import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { loadingRequest } from "src/app/services/appconst";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import {
  DashitemServiceService,
  IDashItemCategoryData,
  IDashItemData,
} from "src/app/services/dashitem-service.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-edit-dash-item-form",
  templateUrl: "./edit-dash-item-form.component.html",
  styleUrls: ["./edit-dash-item-form.component.scss"],
})
export class EditDashItemFormComponent implements OnInit {
  @Input() dashItems: IDashItemData;

  categoryLoadingState = loadingRequest.idle;
  catogories: IDashItemCategoryData[] = [];

  categories = ["categories"];
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  editorConfig = {
    menubar: false, // Set this to false to remove the menu
    plugins: ["image"],
    toolbar:
      "formatselect | bold italic underline | alignleft aligncenter alignright alignjustify| undo redo  | image | ",
    images_upload_url:
      environment.apiBaseEndPoint + "/api/v1/misc/tiny-cloud/save-image.ashx",
    // Add other configurations as needed
  };

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralserviceService,
    public dashItemService: DashitemServiceService,
    public authservice: AuthApiserviceService
  ) {
    this.loadCategory();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      id: [this.dashItems.itemId, [Validators.required]],
      title: [this.dashItems.title, [Validators.required]],
      noOfItems: [this.dashItems.noOfItems, [Validators.required]],
      description: [this.dashItems.description, [Validators.required]],
      itemCategoryId: [this.dashItems.itemCategory.id, [Validators.required]],
      isPrivate: this.dashItems.isPrivate,
    });
  }

  async loadCategory() {
    this.categoryLoadingState = loadingRequest.loading;
    await this.dashItemService
      .getDashItemCategory({})
      .subscribe(
        (res) => {
          // console.log(res);
          this.catogories = res.data;
          this.categoryLoadingState = loadingRequest.success;
        },
        (err) => {
          this.categoryLoadingState = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }

  async proceed() {
    // Get Value from the form in form of an object
    const formValue: any = this.myForm.value;
    // console.log(formValue);

    if (!this.myForm.valid) {
      this.generalService.presentToast("Form not valid, check your form input");
      return;
    }

    this.startproceessing = true;

    this.dashItemService
      .updateDashItem(formValue)
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast(
            "Your Dash Item Has Being Updated",
            "",
            2000,
            "toast-custom-class-success"
          );
          this.dashItemService.onReloadTriggered.next(true);
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || "Error Connecting to server",
            "",
            5000,
            "toast-custom-class-error"
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }
}
