/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from "@angular/core";
import {
  AlertController,
  AlertInput,
  NavController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Browser } from "@capacitor/browser";
import { Observable } from "rxjs/internal/Observable";
import { HttpClient } from "@angular/common/http";
import { Preferences } from "@capacitor/preferences";

@Injectable({
  providedIn: "root",
})
export class GeneralserviceService {
  public onlineAppConifg: string =
    "https://idashyou.github.io/idashu_web_pages/appconfig.json";

  appCurrentVersion = 4;
  public onlineAppConfigValue: IAppConfigType = {
    remoteVersionCode: 5,
    appUpdateUrlAndroid: "https://idashyou.ng/",
    appUpdateUrlios: "https://idashyou.ng/",
    forceUpdate: false,
    useRemoteSite: false,
    remoteSiteURL: "https://idashyou.ng/",
    remoteSiteLoginURL: "https://idashyou.ng/authpage?tab=login",
  };

  constructor(
    private toastController: ToastController,
    public alertController: AlertController,
    public navctrl: NavController,
    public platform: Platform,
    public http: HttpClient
  ) {
    this.myinit();
  }

  async myinit() {
    await this.loadAppConfig();
  }

  getAppConfig(): Observable<any> {
    var curDate = new Date();
    return this.http.get(
      this.onlineAppConifg + "?version=" + curDate.toUTCString()
    );
  }

  getAppTestimony(): Observable<any> {
    var curDate = new Date();
    return this.http.get(
      this.onlineAppConifg + "?version=" + curDate.toUTCString()
    );
  }

  getAppEvents(): Observable<any> {
    var curDate = new Date();
    return this.http.get(
      this.onlineAppConifg + "?version=" + curDate.toUTCString()
    );
  }

  async loadAppConfig() {
    this.getAppConfig()
      .subscribe(
        (res) => {
          // console.log(res);
          this.onlineAppConfigValue = res;
          console.log(this.onlineAppConfigValue);
          this.saveOnlineConfig(res);
        },
        (err) => {
          console.log(err);
          this.readOnlineConfig();
        }
      )
      .add(() => {});
  }

  async saveOnlineConfig(val) {
    await Preferences.set({
      key: "appConfigIdashyou",
      value: JSON.stringify(val),
    });
    console.log("App Config Saved");
  }

  async readOnlineConfig() {
    const ret = await Preferences.get({ key: "appConfigIdashyou" });
    if (ret.value == null) {
      return;
    }
    console.log(JSON.parse(ret.value));
    this.onlineAppConfigValue = JSON.parse(ret.value);
  }

  async platformDimension() {
    const deviceSize = await this.platform.ready().then(() => {
      return { width: this.platform.width(), height: this.platform.height() };
    });

    return deviceSize;
  }

  async presentToast(
    message: string,
    header?: string,
    duration?: any,
    cssClass?:
      | "toast-custom-class"
      | "toast-custom-class-error"
      | "toast-custom-class-success"
      | "toast-custom-class-warning"
      | "toast-custom-class-info"
  ) {
    const toast = await this.toastController.create({
      header: header || "",
      message,
      duration: duration || 5000,
      cssClass: cssClass || "toast-custom-class", // css class are defined on the global.scss file
      buttons: [
        {
          side: "end",
          icon: "close-circle",
          handler: () => {},
        },
      ],
    });

    await toast.present();
  }

  async presentBasicAlert(header, subheader, message, callBack: () => {}) {
    const alert = await this.alertController.create({
      header,
      subHeader: subheader,
      message,
      buttons: [
        {
          text: "Okay",
          role: "cancel",
          handler: callBack,
        },
      ],
    });
    await alert.present();
  }

  async presentBasicAlertDialog(
    header,
    subheader,
    message,
    btnSuccesName: string,
    btnCancelName: string,
    successcallBack: () => void,
    cancelcallBack: () => void
  ) {
    const alert = await this.alertController.create({
      header,
      subHeader: subheader,
      message,
      buttons: [
        {
          text: btnSuccesName,
          role: "okay",
          handler: successcallBack,
        },
        {
          text: btnCancelName,
          role: "cancel",
          handler: cancelcallBack,
        },
      ],
    });
    await alert.present();
  }

  async PresentBasicPrompt(
    title?: string,
    btnSuccessName?: string,
    inputs?: AlertInput[],
    callBack?: (data: any) => void
  ) {
    const alert = await this.alertController.create({
      header: title,
      buttons: [
        {
          text: btnSuccessName,
          handler: callBack,
        },
      ],
      inputs,
    });

    await alert.present();
  }

  navigatePage(reDirectionPage) {
    this.navctrl.navigateForward(reDirectionPage);
  }

  getFormData(data: any): FormData {
    const formData = new FormData();
    const keys = Object.keys(data);
    keys.forEach((key) => {
      formData.append(key, data[key] ?? "");
    });
    return formData;
  }

  getAndAppendFormData(data: any, formData: FormData) {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      formData.set(key, data[key] ?? "");
    });
  }

  convertToShortDate(date: string) {
    return date.replace("T00:00:00", "");
  }

  convertToShortDate_b(date: string) {
    const curdate = new Date(date);
    return curdate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      weekday: "short",
    });
  }

  convertToShortTime(date: string) {
    const curdate = new Date(date);
    return curdate.toLocaleTimeString();
  }

  utcToHtmlDateTime(utcDate) {
    const date = new Date(utcDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  utcToHtmlDate(utcDate) {
    const date = new Date(utcDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  timeSince(datestring, testMode?: boolean) {
    let newdate = new Date();

    // Used for Unit Test
    if (testMode) {
      newdate = new Date("2023-07-30T12:00:00");
    }

    const serverdate = new Date(datestring);
    const datediff = Number(newdate.getTime()) - Number(serverdate.getTime());

    let seconds = Math.floor(datediff / 1000);
    // console.log(seconds - 3600);

    seconds = seconds - 3600;

    let interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
      return interval + " yrs";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval + " mon";
    }
    interval = Math.floor(seconds / 86400);
    // console.log(interval)
    if (interval >= 1) {
      return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval + " hrs";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " min";
    }
    return "Just Now";
  }

  daysDifference(startDate, endDate): number {
    let today = new Date(startDate);
    if (startDate === null) {
      today = new Date();
    }
    // console.log(today);

    let form_date = new Date(endDate);
    if (endDate === null) {
      form_date = new Date();
    }

    let difference = ((form_date as any) - (today as any)) as number;
    let diff_days = Math.floor(difference / (1000 * 3600 * 24));
    // console.log(diff_days);
    return diff_days;
  }

  convertDateToUTC(dateString: string) {
    const curDate = new Date(dateString);
    return curDate.toISOString();
  }

  strictStringToNumber(input) {
    return Number(input);
  }

  validateYouTubeUrl(url: string): {
    state: boolean;
    url: string;
  } {
    if (url != undefined || url != "") {
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      return {
        state: match && match?.[2].length == 11,
        url:
          "https://www.youtube.com/embed/" +
          match?.[2] +
          "?autoplay=1&enablejsapi=1",
      };
    } else {
      return { state: false, url: "" };
    }
  }

  convertHtmlToPlainText(element?) {
    if (!element) {
      return "";
    }
    return element.replace(/<[^>]*>/g, "");
  }

  convertAdjustHTMLImage(element?) {
    if (!element) {
      return "";
    }
    return element
      .replace(/height=\"\d*\"/gi, "")
      .replace(/width=\"\d*\"/gi, "width='100%'");
  }

  async openwebpage(webpage: string) {
    await Browser.open({
      url: webpage,
      toolbarColor: "#619043",
    });
  }
}

export interface IAppConfigType {
  remoteVersionCode: number;
  appUpdateUrlAndroid: string;
  appUpdateUrlios: string;
  forceUpdate: boolean;
  useRemoteSite: boolean;
  remoteSiteURL: string;
  remoteSiteLoginURL: string;
}
