import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SafeSrcUrlDirective } from "./safe-src-url.directive";

@NgModule({
  declarations: [SafeSrcUrlDirective],
  imports: [CommonModule],
  exports: [SafeSrcUrlDirective],
})
export class SafeSrcUrlModule {}
