import { Component, OnInit } from "@angular/core";
import { OverlayEventDetail } from "@ionic/core/components";

@Component({
  selector: "app-create-cause-comp",
  templateUrl: "./create-cause-comp.component.html",
  styleUrls: ["./create-cause-comp.component.scss"],
})
export class CreateCauseCompComponent implements OnInit {
  
  causeList = [
    {
      name: "Raise Funds",
      caption: "Create a fundraiser today",
      image: "assets/images/create-cause-item-fundraiser.svg",
      buttonName: "Raise Funds",
    },
    {
      name: "Dash Items",
      caption: "Share and receive gift items.",
      image: "assets/images/create-cause-item-dash-item.svg",
      buttonName: "Dash Items",
    },
    {
      name: "Create Wishlists",
      caption: "Create and share a Wishlist to get funds.",
      image: "assets/images/create-cause-item-wishlist.svg",
      buttonName: "Create Wishlists",
    },
  ];

  isModalOpen: boolean = false;
  modalIndex = 0;
  modalTitle: string = "";

  constructor() {}

  ngOnInit() {}

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    // console.log(ev.detail);
    this.isModalOpen = false;
  }

  toogleModal(isOpen: boolean, title?: string, index?: number) {
    this.isModalOpen = isOpen;
    this.modalTitle = title;
    this.modalIndex = index;
  }
}
