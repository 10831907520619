import { Component, OnInit } from '@angular/core';
import { AddFundraiserProjectService } from './services/add-fundraiser-project.service';
@Component({
  selector: 'app-add-fundraiser-project',
  templateUrl: './add-fundraiser-project.component.html',
  styleUrls: ['./add-fundraiser-project.component.scss'],
})
export class AddFundraiserProjectComponent implements OnInit {
  constructor(public addFundRaiserService: AddFundraiserProjectService) {}

  ngOnInit() {}
}
