import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";

@Component({
  selector: "app-custom-select-box",
  templateUrl: "./custom-select-box.component.html",
  styleUrls: ["./custom-select-box.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectBoxComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomSelectBoxComponent),
      multi: true,
    },
  ],
})
export class CustomSelectBoxComponent implements OnInit {
  @ViewChild("selectFocus") selectFocus: ElementRef;
  // Props for Customisation
  @Input() iconType: "ionicon" | "image" | "none" = "none";
  @Input() ionIcon: string = "mail-outline";
  @Input() iconUrl: string = "assets/images/iconauthpic1.svg";
  @Input() placeHolder: string = "Enter Text";
  @Input() hasError: Boolean = false;
  // Props for NgModel
  @Input() customNgModal: string;
  @Output() customNgModalChange = new EventEmitter<string>();
  @Input() required: Boolean = true;

  // Variable for Form Control
  value: string;
  disabled = false;
  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  //Variable for Customisation
  passwordVisible: Boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.value = this.customNgModal;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // Perform validation logic here
    if ((!control.value || control.value === "") && this.required) {
      return { required: true };
    }
    return null;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  modelChange($event) {
    this.customNgModalChange.emit($event.target.value);
    this.onChange(this.value);
  }

  setFocus() {
    setTimeout(() => {
      // this will make the execution after the above boolean has changed
      this.selectFocus.nativeElement.focus();
    }, 0);
  }
}
