import { Component, Input, OnInit } from "@angular/core";
import { GeneralserviceService } from "src/app/services/generalservice.service";

@Component({
  selector: "app-form-success-alert",
  templateUrl: "./form-success-alert.component.html",
  styleUrls: ["./form-success-alert.component.scss"],
})
export class FormSuccessAlertComponent implements OnInit {
  @Input() title: string = "";
  @Input() subTitle: string = "";
  @Input() link: string = "";
  @Input() hasLink: boolean = false;
  @Input() imageIcon: string = "assets/images/successAlert.svg";

  constructor(public generalService: GeneralserviceService) {}

  ngOnInit() {}

  copyText() {
    // Get the text field
    const copyText: any = document.getElementById("linkCreatedText");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    window.navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    this.generalService.presentToast("Link Copied");
  }
}
