import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthApiserviceService } from './authapiservice.service';
import { GeneralserviceService } from './generalservice.service';

const apiBaseEndPoint = environment.apiBaseEndPoint;

// const STAGING = 'https://stagingapi.idashyou.ng/';
// const REMOTE_HOST = 'https://stagingapi.idashyou.ng/';

// if (environment.production) {
//   apiBaseEndPoint = REMOTE_HOST;
// }

const addchats = `${apiBaseEndPoint}api/v1/user/chat-messages/add-chat-message.ashx`;
const deleteChatApi = `${apiBaseEndPoint}api/v1/user/chat-messages/delete-chat-message.ashx`;
const getChatsUsers = `${apiBaseEndPoint}/api/v1/user/projects/get-projects.ashx`;
const getChatsRoom = `${apiBaseEndPoint}api/v1/user/chat-rooms/get-chat-rooms.ashx`;
const getChatsMessage = `${apiBaseEndPoint}api/v1/user/chat-messages/get-chat-messages.ashx`;

@Injectable({
  providedIn: 'root',
})
export class ChatServiceService {
  onMessageSent: BehaviorSubject<boolean> = new BehaviorSubject(false);

  unReadMessage = 0;
  currentChatPage: IChatRoomData = null;
  disabledMobileTab = false;

  messageToSend = '';
  attachment = null;
  attachmentPreview = '';
  formData = new FormData();
  startproceessing = false;

  constructor(
    private httpClient: HttpClient,
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {}

  //#region  Add Region

  addChatMessage(body: any): Observable<any> {
    return this.httpClient.post<any>(addchats, body, {
      headers: this.authservice.header,
    });
  }

  //#region  Delete Region

  deleteChat(body: any): Observable<any> {
    return this.httpClient.post<any>(deleteChatApi, body, {
      headers: this.authservice.header,
    });
  }

  //#region  GET Region

  getChatsUsers(params: any): Observable<any> {
    return this.httpClient.get<any>(getChatsUsers, {
      headers: this.authservice.header,
      params,
    });
  }

  getChatMessage(params: any): Observable<IChatMessage> {
    return this.httpClient.get<IChatMessage>(`${getChatsMessage}`, {
      headers: this.authservice.header,
      params,
    });
  }

  getChatRooms(params: any): Observable<IChatRoom> {
    return this.httpClient.get<IChatRoom>(`${getChatsRoom}`, {
      headers: this.authservice.header,
      params,
    });
  }

  //#region  Chat Display Region

  updateDisabledMobileTab(state: boolean) {
    this.disabledMobileTab = state;
  }

  updateCurrentChatPage(chatRoomData: IChatRoomData) {
    this.currentChatPage = chatRoomData;
    // console.log(this.currentChatPage);
  }

  //# Send Message Manipulation
  async generateChatPayLoad() {
    this.clearFormData();
    this.formData.append('message', this.messageToSend);
    // this.formData.append('to', null);
    this.formData.append('chatroomId', this.currentChatPage.chatRoomId);
    // this.formData.append('replymessageid', null);
    this.sendMessage();
  }

  async clearFormData() {
    try {
      this.formData.delete('message');
      this.formData.delete('chatroomId');
      this.formData.delete('attachment');
    } catch (err) {}
  }

  async generateChatAttachmentPayLoad() {
    this.clearFormData();
    this.formData.append('message', this.messageToSend);
    // this.formData.append('to', null);
    this.formData.append('chatroomId', this.currentChatPage.chatRoomId);
    // this.formData.append('replymessageid', null);
    this.formData.append('attachment', this.attachment);
    this.sendMessage();
  }

  async sendMessage() {
    this.startproceessing = true;

    this.addChatMessage(this.formData)
      .subscribe(
        (res) => {
          // console.log(res);
          this.onMessageSent.next(true);
          this.clearFormData();
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || 'Error Connecting to server',
            '',
            5000,
            'toast-custom-class-error'
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }
}

// Types for ChatUser/Room
export interface IChatRoom {
  message: string;
  data: IChatRoomData[];
  meta: Meta;
}

export interface IChatRoomData {
  chatRoomId: string;
  name: string;
  isPublic: boolean;
  imageUrl: string;
  lastMessage: string;
  lastMessageSentBy: string;
  lastMessageAt: string;
  dateCreated: string;
}

export interface Meta {
  total: number;
}

// Type for Chat Message
export interface IChatMessage {
  message: string;
  data: IChatMessageData[];
  meta: Meta;
}

export interface IChatMessageData {
  chatMessageId: string;
  chatRoomId: string;
  message: string;
  sentByMe: boolean;
  sentBy?: SentBy;
  replyChatMessageId: any;
  hasReply: boolean;
  replyMessage: string;
  status: any;
  externalReplyType: number;
  externalReplyTypeId: string;
  attachments: Attachment;
  dateCreated: string;
}

export interface SentBy {
  userId: string;
  name: string;
  dpUrl: string;
}

export interface Attachment {
  id: string;
  url?: string;
  type: string;
}

export interface Meta {
  total: number;
}
