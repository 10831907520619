import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-resetpasswordcomp",
  templateUrl: "./resetpassword.component.html",
  styleUrls: ["./resetpassword.component.scss"],
})
export class ResetpasswordComponent implements OnInit {
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;
  deviceSize;
  isMobileApp = false;

  @Output() loginStatusCode: EventEmitter<any> = new EventEmitter();
  @Output() reDirectionPage: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {
    this.logout();
    this.onInit();
  }

  async onInit() {
    this.deviceSize = await this.generalService.platformDimension();
    this.isMobileApp = environment.mobileApp;
    // console.log(this.deviceSize);
  }

  ionViewDidEnter() {
    this.startproceessing = false;
    this.logout();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }

  async logout() {}

  async proceed() {
    if (!this.myForm.valid) {
      this.generalService.presentToast("Form not valid, check your form input");
      return;
    }

    this.startproceessing = true;

    this.authservice
      .checkEmail(this.myForm.value)
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast(
            "Password Reset Instruction has being sent to your Email"
          );
          this.popStatusCode(res["status"]);
        },
        (err) => {
          // console.log(err);
          this.popStatusCode(err["status"]);
          this.generalService.presentToast(err.error?.message);
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }

  async presentToast(msg) {}

  navigate(pagename) {
    this.reDirectionPage.emit(pagename);
  }

  popStatusCode(index) {
    this.loginStatusCode.emit(index);
  }
}
