import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';
import { OrganisationsServiceService } from 'src/app/services/organisations-service.service';
import { OverlayEventDetail } from '@ionic/core/components';
import {
  IwishListData,
  IWishListItem,
  WishlistServiceService,
} from 'src/app/services/wishlist-service.service';

@Component({
  selector: 'app-edit-wish-list-items',
  templateUrl: './edit-wish-list-items.component.html',
  styleUrls: ['./edit-wish-list-items.component.scss'],
})
export class EditWishListItemsComponent implements OnInit {
  @Input() wishList: IwishListData = null;

  isModalOpen = false;
  modalTitle = 'Title';
  modalIndex = 1;
  modalTab = 0;

  myFormList: FormGroup = new FormGroup({});
  startproceessing = false;
  wishListItemTotal = 0;

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralserviceService,
    public wishListService: WishlistServiceService,
    public authservice: AuthApiserviceService
  ) {}

  ngOnInit() {
    this.myFormList = this.fb.group({
      wishlistid: [this.wishList.wishlistId, Validators.required],
      itemname: ['', Validators.required],
      price: [0, Validators.required],
      imageUrl: ['https://cdn-icons-png.flaticon.com/512/428/428001.png'],
    });

    this.wishListItemTotal = this.wishList.items.reduce(
      (accumulator, currentValue) => accumulator + currentValue.price,
      0
    );
  }

  async proceed() {
    // Get Value from the form in form of an object
    const formValue: any = this.myFormList.value;
    // console.log(formValue);

    if (!this.myFormList.valid) {
      this.generalService.presentToast('Form not valid, check your form input');
      return;
    }

    this.startproceessing = true;

    this.wishListService
      .addWishListItem(formValue)
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast(
            'New Wish List Item has being Added, Refresh Page to see Effect',
            '',
            2000,
            'toast-custom-class-success'
          );
          this.wishListService.onWishListItemsEdited.next(true);
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || 'Error Connecting to server',
            '',
            5000,
            'toast-custom-class-error'
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    // console.log(ev.detail);
    this.isModalOpen = false;
  }

  toogleModal(isOpen: boolean, title?: string, index?: number) {
    this.isModalOpen = isOpen;
    this.modalTitle = title;
    this.modalIndex = index;
  }

  changeModalTab(index) {
    this.modalTab = index;
  }

  updateIconImage($event) {
    this.myFormList.patchValue({
      imageUrl: $event,
    });
    this.isModalOpen = false;
  }

  deleteDashWishListItem(index) {
    const cssClass = 'toast-custom-class-success';

    this.generalService.presentBasicAlertDialog(
      '',
      'Delete',
      'Are you sure You want to Delete this Item',
      'Ok',
      'Cancel',
      () => {
        this.deleteConfirmed(this.wishList.items[index].id);
      },
      () => {
        this.generalService.presentToast(
          'Operation Cancel',
          '',
          2000,
          'toast-custom-class-error'
        );
      }
    );
  }

  deleteConfirmed(id) {
    this.wishListService.deleteWishListItem({ id }).subscribe(
      (res) => {
        // console.log(res);
        this.generalService.presentToast(
          'Delete Successful',
          '',
          2000,
          'toast-custom-class-success'
        );

        this.wishListService.onWishListItemsEdited.next(true);
      },
      (err) => {
        // console.log(err);
        this.generalService.presentToast(
          err.error?.message || 'Error Connecting to server',
          '',
          5000,
          'toast-custom-class-error'
        );
      }
    );
  }
}
