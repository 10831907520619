import { Component, OnInit, ViewChild } from "@angular/core";
import {
  LoadingController,
  MenuController,
  NavController,
  ToastController,
} from "@ionic/angular";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import { IonModal } from "@ionic/angular";
import { OverlayEventDetail } from "@ionic/core/components";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-sidemenu",
  templateUrl: "./sidemenu.component.html",
  styleUrls: ["./sidemenu.component.scss"],
})
export class SidemenuComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  pathname = "";
  currentOrganisation: string = "";
  isModalOpen: boolean = false;
  modalIndex = 0;
  modalTitle: string = "";
  isMobileApp = false;

  public appPages = [
    {
      title: "Home",
      url: "/dashboard",
      icon: "home-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
      highligthedPath: ["/dashboard"],
    },
    {
      title: "Fundraisers",
      url: "/fundraiserdonation-me",
      icon: "heart-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
      highligthedPath: [
        "/fundraiserdonation-me",
        "/fundraiserdonations",
        "edit-cause-fundraiser-donations",
      ],
    },
    {
      title: "Dash Pledges",
      url: "/fundraiserdonationcategory",
      icon: "heart-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
      highligthedPath: ["/fundraiserdonationcategory"],
    },
    {
      title: "Dash Stuff",
      url: "/dashitems-me",
      icon: "cart-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
      highligthedPath: ["/dashitems-me", "dashitems"],
    },
    {
      title: "WishLists",
      url: "/wishlist-me",
      icon: "gift-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
      highligthedPath: ["/wishlist-me", "wishlists"],
    },
    {
      title: "Dash Raffles",
      url: "/raffle-me",
      icon: "ticket-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
      highligthedPath: ["/raffle-me", "raffle-explore"],
    },
    // {
    //   title: 'Profile',
    //   url: '/profile',
    //   icon: 'person-outline',
    //   imageurl: 'assets/images/svg/home.svg',
    //   subtitle: 'View Now',
    //   label: false,
    // },
    {
      title: "Dash Games",
      url: "/trivia-games",
      icon: "game-controller-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
      highligthedPath: ["/trivia-games"],
    },
    {
      title: "Wallet",
      url: "/wallets",
      icon: "wallet-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
      highligthedPath: ["/wallets"],
    },
    {
      title: "Logout",
      url: "/logout",
      icon: "log-out-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
      highligthedPath: ["/logout"],
    },
  ];

  constructor(
    public http: HttpClient,
    public toastController: ToastController,
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService,
    private router: Router
  ) {
    this.isMobileApp = environment.mobileApp;
    router.events.subscribe((val) => {
      this.getCurrentPageDetails();
    });
  }

  async getCurrentPageDetails() {
    await this.authservice.onLoad();
    // await this.getActiveOrganisation();
    this.getpathname();
  }

  ngOnInit() {
    if (this.isMobileApp) {
      this.appPages = [
        {
          title: "Home",
          url: "/dashboard",
          icon: "home-outline",
          imageurl: "assets/images/svg/home.svg",
          subtitle: "View Now",
          label: false,
          highligthedPath: ["/dashboard"],
        },
        {
          title: "Fundraisers",
          url: "/fundraiserdonation-me",
          icon: "heart-outline",
          imageurl: "assets/images/svg/home.svg",
          subtitle: "View Now",
          label: false,
          highligthedPath: [
            "/fundraiserdonation-me",
            "/fundraiserdonations",
            "edit-cause-fundraiser-donations",
          ],
        },
        {
          title: "Dash Pledges",
          url: "/fundraiserdonationcategory",
          icon: "heart-outline",
          imageurl: "assets/images/svg/home.svg",
          subtitle: "View Now",
          label: false,
          highligthedPath: ["/fundraiserdonationcategory"],
        },
        {
          title: "Dash Stuff",
          url: "/dashitems-me",
          icon: "cart-outline",
          imageurl: "assets/images/svg/home.svg",
          subtitle: "View Now",
          label: false,
          highligthedPath: ["/dashitems-me", "dashitems"],
        },
        {
          title: "WishLists",
          url: "/wishlist-me",
          icon: "gift-outline",
          imageurl: "assets/images/svg/home.svg",
          subtitle: "View Now",
          label: false,
          highligthedPath: ["/wishlist-me", "wishlists"],
        },
        // {
        //   title: "Dash Raffles",
        //   url: "/raffle-me",
        //   icon: "ticket-outline",
        //   imageurl: "assets/images/svg/home.svg",
        //   subtitle: "View Now",
        //   label: false,
        //   highligthedPath: ["/raffle-me", "raffle-explore"],
        // },
        // {
        //   title: 'Profile',
        //   url: '/profile',
        //   icon: 'person-outline',
        //   imageurl: 'assets/images/svg/home.svg',
        //   subtitle: 'View Now',
        //   label: false,
        // },
        {
          title: "Dash Games",
          url: "/trivia-games",
          icon: "game-controller-outline",
          imageurl: "assets/images/svg/home.svg",
          subtitle: "View Now",
          label: false,
          highligthedPath: ["/trivia-games"],
        },
        {
          title: "Wallet",
          url: "/wallets",
          icon: "wallet-outline",
          imageurl: "assets/images/svg/home.svg",
          subtitle: "View Now",
          label: false,
          highligthedPath: ["/wallets"],
        },
        {
          title: "Logout",
          url: "/logout",
          icon: "log-out-outline",
          imageurl: "assets/images/svg/home.svg",
          subtitle: "View Now",
          label: false,
          highligthedPath: ["/logout"],
        },
      ];
    }
  }

  async getActiveOrganisation() {
    // const a = this.authservice.activeOrganisation.name || 'Personal Account';
    // if (!this.authservice.userDetails) return;
    // // this.currentOrganisation =
    // //   this.authservice.userDetails.organizations.filter(
    // //     (x) => x.active === true
    // //   )[0].name || 'Personal Account';
  }

  getpathname() {
    this.pathname = window.location.pathname;
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    // console.log(ev.detail);
    this.isModalOpen = false;
  }

  toogleModal(isOpen: boolean, title?: string, index?: number) {
    this.isModalOpen = isOpen;
    this.modalTitle = title;
    this.modalIndex = index;
  }

  navigateHome() {
    this.isMobileApp
      ? this.generalService.openwebpage("https://idashyou.ng/home")
      : this.generalService.navigatePage("/home");
  }

  highLightPath(paths: string[], label: boolean) {
    return paths.includes(this.pathname) && !label;
  }
}
