/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Preferences } from "@capacitor/preferences";
import { Device } from "@capacitor/device";
import { Site_Accees_Token, Token_Key, User_Key } from "./appconst";
import { GeneralserviceService } from "./generalservice.service";

const apiBaseEndPoint = environment.apiBaseEndPoint;

// const STAGING = 'https://stagingapi.idashyou.ng/';
// const REMOTE_HOST = 'https://stagingapi.idashyou.ng/';

// if (environment.production) {
//   apiBaseEndPoint = REMOTE_HOST;
// }

const avatarDp = `${apiBaseEndPoint}/api/v1/user/avatars/get-avatars.ashx`;
const refreshLoginRoute = `${apiBaseEndPoint}/api/v1/user/auth/sessions-login.ashx`;
const loginroute = `${apiBaseEndPoint}/api/v1/user/auth/login.ashx`;
const signUpRoute = `${apiBaseEndPoint}/api/v1/user/auth/signup.ashx`;
const checkEmailRoute = `${apiBaseEndPoint}/api/v1/user/auth/forgot-password/send-email.ashx`;
const resetPasswordRoute = `${apiBaseEndPoint}/api/v1/user/auth/forgot-password/confirm-token.ashx`;

const disableAccount = `${apiBaseEndPoint}/api/v1/user/auth/disable-account.ashx`;
const sendVerifyEmailToken = `${apiBaseEndPoint}/api/v1/user/auth/verify-email/send-email.ashx`;
const verifyEmailToken = `${apiBaseEndPoint}/api/v1/user/auth/verify-email/confirm-token.ashx`;

const updateProfileRoute = `${apiBaseEndPoint}/api/v1/user/auth/update-details.ashx`;
const updatePasswordRoute = `${apiBaseEndPoint}/api/v1/user/auth/reset-password.ashx`;
const updateDpdRoute = `${apiBaseEndPoint}/api/v1/user/auth/update-dp.ashx`;

const getProfileActivites = `${apiBaseEndPoint}/api/v1/user/auth/get-profile-activities.ashx`;

const getVerificationOption = `${apiBaseEndPoint}/api/v1/user/verification-options/get-options.ashx`;
const getVerificationForm = `${apiBaseEndPoint}/api/v1/user/verification-requests/add-request.ashx`;
const updateVerificationForm = `${apiBaseEndPoint}/api/v1/user/verification-logs/add-log.ashx`;
const getOthersProfileApi = `${apiBaseEndPoint}/api/v1/user/users/explore-users.ashx`;

// Add Traffic Log
const saveTraffic = `${apiBaseEndPoint}/api/v1/user/user-traffic/save-traffic.ashx`;

@Injectable({
  providedIn: "root",
})
export class AuthApiserviceService {
  authorization = "";
  header: any = {};
  resetPasswordToken = "";
  userDetails: IuserDataCredentials = null;
  activeOrganisation: IorganisationBasic = null;
  signInRedirectPage = null;

  otherUserProfile: IOtherUsersData = null;

  constructor(
    private httpClient: HttpClient,
    public generalService: GeneralserviceService
  ) {
    // This is used to refresh Tokens and User details everytime this services is called or the page when this service is used is force Reload
    this.onLoad();
  }

  async onLoad() {
    // Load Default Headers for most of the Api
    this.header = await this.getheader();
    //this.loginToken = await this.getLoginToken();
    this.userDetails = await this.getuserdetails();
    this.activeOrganisation = await this.getActiveOrganisation();
    await this.checkSession();
  }

  getAvatars(params: any): Observable<any> {
    return this.httpClient.get(avatarDp, {
      headers: this.header,
      params,
    });
  }

  loginRefresh(): Observable<IUserData> {
    return this.httpClient.get(refreshLoginRoute, {
      headers: this.header,
    });
  }

  login(body: ILoginData): Observable<IUserData> {
    return this.httpClient.post<IUserData>(loginroute, body, {
      headers: this.header,
    });
  }

  signUp(body: ISignUpData): Observable<IUserData> {
    return this.httpClient.post<IUserData>(signUpRoute, body, {
      headers: this.header,
    });
  }

  checkEmail(body: any): Observable<any> {
    return this.httpClient.post(checkEmailRoute, body, {
      headers: this.header,
    });
  }

  resetPassword(body: any): Observable<any> {
    body.token = this.resetPasswordToken;
    return this.httpClient.post(resetPasswordRoute, body, {
      headers: this.header,
    });
  }

  sendVerifyEmailToken(): Observable<any> {
    return this.httpClient.get(sendVerifyEmailToken, {
      headers: this.header,
    });
  }

  verifyEmail(body: any): Observable<any> {
    return this.httpClient.post(verifyEmailToken, body, {
      headers: this.header,
    });
  }

  disabledAccount(): Observable<any> {
    return this.httpClient.post(disableAccount, {
      headers: this.header,
    });
  }

  updateProfile(body: IUpdateProfileData): Observable<any> {
    return this.httpClient.post(updateProfileRoute, body, {
      headers: this.header,
    });
  }

  updatePassword(body: IUpdatePasswordData): Observable<any> {
    return this.httpClient.post(updatePasswordRoute, body, {
      headers: this.header,
    });
  }

  updateDp(body: any): Observable<any> {
    return this.httpClient.post(updateDpdRoute, body, {
      headers: this.header,
      responseType: "json",
      reportProgress: true, // this is used to show the progress of upload
      observe: "events", // this will help to print the refresh all observable including the progress
    });
  }

  getProfileActivites(params: any): Observable<any> {
    return this.httpClient.get(getProfileActivites, {
      headers: this.header,
      params,
    });
  }

  getOthersProfile(params: any): Observable<any> {
    return this.httpClient.get(getOthersProfileApi, {
      headers: this.header,
      params,
    });
  }

  getVerificationForm(body: any): Observable<any> {
    return this.httpClient.post(getVerificationForm, body, {
      headers: this.header,
    });
  }

  getVerificationOption(params: any): Observable<any> {
    return this.httpClient.get(getVerificationOption, {
      headers: this.header,
      params,
    });
  }

  updateVerificationForm(body: any): Observable<any> {
    return this.httpClient.post(updateVerificationForm, body, {
      headers: this.header,
    });
  }

  saveUserTraffic(body: any): Observable<any> {
    return this.httpClient.post(saveTraffic, body, {
      headers: this.header,
    });
  }

  /**
   *  This section are use to store User/Login Details and Token
   */

  async storeLoginToken(loginToken: any) {
    await Preferences.set({
      key: Token_Key,
      value: loginToken,
    });
    // This update the Header with the Latest Token
    this.header = await this.getheader();
  }

  async storeUserdetails(userdetails: any) {
    await Preferences.set({
      key: User_Key,
      value: JSON.stringify(userdetails),
    });
    // This update the Header with the User Details
    this.userDetails = await this.getuserdetails();
    this.activeOrganisation = await this.getActiveOrganisation();
  }

  async logout() {
    await Preferences.remove({ key: Token_Key });
    await Preferences.remove({ key: User_Key });
    //  Set the latest state of the Auth Token and User Details
    this.header = await this.getheader();
    this.userDetails = await this.getuserdetails();
  }

  /**
   *  End of storage section
   */

  /**
   *  This section are use to get login details headers
   */

  async getuserdetails() {
    const { value } = await Preferences.get({ key: User_Key });
    return JSON.parse(value);
  }

  async getLoginToken() {
    const { value } = await Preferences.get({ key: Token_Key });
    return value;
  }

  async getheader() {
    const info = await Device.getId();
    this.authorization = await this.getLoginToken();

    const headers = {
      uuid: info.identifier,
      Authorization: "Bearer " + this.authorization + "",
    };
    return headers;
  }

  async getActiveOrganisation() {
    if (!this.userDetails) {
      return null;
    }
    return this.userDetails.organizations.filter((x) => x.active === true)[0];
  }

  /**
   *  JWT Session
   */
  decodeJWT(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  async checkSession() {
    if (!this.authorization) {
      return;
    }
    const jwt: IJwtResponse = this.decodeJWT(this.authorization);
    const expDate = new Date(jwt.exp * 1000);
    const curDate = new Date();
    // console.log('Session Expire by', expDate);

    if (curDate > expDate) {
      this.signInRedirectPage = window.location.pathname;
      this.generalService.presentToast("Your Session Has Expired");
      this.authorization = "";
      this.generalService.navigatePage("/logout");
      this.logout();
    }
    // console.log(expDate);
  }

  async checkStagingAccess() {
    const { value } = await Preferences.get({ key: Site_Accees_Token });
    return value === environment.siteAccessToken;
  }

  async saveStagingAccess(accessToken) {
    await Preferences.set({
      key: Site_Accees_Token,
      value: accessToken,
    });
  }

  async clearStagingAccess() {
    await Preferences.remove({ key: Site_Accees_Token });
  }
}

export interface ILoginData {
  email: string;
  password: string;
}

export interface ISignUpData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  otherNames: string;
  phoneNumber: string;
  gender: string;
  city: string;
  country: string;
}

export interface IUserData {
  message?: string;
  data?: {
    token: string;
    credentials: IuserDataCredentials;
  };
}

export type IuserDataCredentials = {
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  otherNames: string | null;
  fullName: string;
  emailVerified: boolean;
  dpUrl: string | null;
  gender?: string;
  address: any;
  city?: string;
  country?: string;
  jobTitle?: string;
  bio?: string;
  phoneNumber?: string | number;
  organizations?: IorganisationBasic[];
  hiddenFields: IhiddenField;
  walletStatus: IWalletStatus;
  noOfProjects: number;
  noOfWishlists: number;
  noOfItems: number;
  noOfUnreadNotifications: number;
  noOfUnreadChat: number;
  username: string;
  rank: string;
  referralCode: string;
  noOfReferrals: number;
  virtualAccount?: VirtualAccount;
};

export interface VirtualAccount {
  id: number;
  accountNumber: string;
  accountName: string;
  bankName: string;
  statusId: number;
  status: string;
  linkedProjectId: number;
  linkedProject: LinkedProject;
}

export interface LinkedProject {
  id: number;
  title: string;
}

export interface WalletStatus {
  walletId: string;
  activated: boolean;
  balance: number;
}

type IWalletStatus = {
  activated: boolean;
  balance: number;
};

type IorganisationBasic = {
  id: string | number;
  name: string;
  primary: boolean;
  active: boolean;
  type: string;
  role: string;
  verified: boolean;
  accessibility: number;
  ownerId: string;
  owner: string;
  badges: Badge[];
};

export interface Badge {
  id: number;
  badgeId: number;
  title: string;
  imageUrl: string;
}

type IhiddenField = {
  fullName: boolean;
  email: boolean;
  phoneNumber: boolean;
  gender: boolean;
  address: boolean;
  city: boolean;
  country: boolean;
  jobTitle: boolean;
  bio: boolean;
};

export interface IUpdateProfileData {
  firstName: string;
  lastName: string;
  otherNames: string;
  phoneNumber: string;
  jobTitle: string;
  bio: string;
}

export interface IUpdatePasswordData {
  oldPassword: string;
  newPassword: string;
}

// type for Verification Form Builder

export interface IVerificationFormBuilder {
  message: string;
  data: IVerificationForm[];
  meta: IVerificationMeta;
}

export interface IVerificationMeta {
  total: number;
  requestStatus: string;
}

export interface IVerificationForm {
  optionId?: string;
  id: number;
  title: string;
  typeId?: number;
  type?: string;
  description?: any;
  fields: IVerificationFormField[];
}

export interface IVerificationFormField {
  id: number;
  fieldId?: string;
  title: string;
  accept: string;
  value?: string;
  message: string;
  sequence?: number;
  minLength?: any;
  maxLength?: any;
  lastUpdated?: any;
  options?: IVerificationFormFieldOption[];
  optional?: boolean;
  description?: any;
  placeholder?: any;
  status?: number | string;
  statusId?: number | string;
}

export interface IVerificationFormFieldOption {
  fieldOptionId?: string;
  id?: string;
  title: string;
}

// Avatar DP Types
export interface IAvatarDp {
  message: string;
  data: IAvatarDpData[];
  meta: Meta;
}

export interface IAvatarDpData {
  avatarId: number;
  imageUrl: string;
  typeId: number;
  dateModified: string;
  dateCreated: string;
}

export interface Meta {
  total: number;
}

// Recent Activities Type
export interface IRecentActivites {
  message: string;
  data: IRecentActivitesData[];
  meta: Meta;
}

export interface IRecentActivitesData {
  profileActivityId: string;
  message: string;
  template: string;
  type: string;
  readStatus: boolean;
  variables?: Variable[];
  dateCreated: string;
}

export interface Variable {
  key: string;
  value: string;
  type: number;
  placeholder: string;
}

export interface Meta {
  total: number;
}

// JSON Web Token
export interface IJwtResponse {
  userId: string;
  uuid: string;
  token: string;
  nbf: number;
  exp: number;
  iat: number;
}

// Other Services

export interface IOtherUsers {
  message: string;
  data: IOtherUsersData[];
  meta: Meta;
}

export interface IOtherUsersData {
  userId: number;
  firstName: string;
  lastName: string;
  otherNames: any;
  fullName: string;
  email: string;
  emailVerified: boolean;
  dpUrl: any;
  phoneNumber: string;
  username: any;
  gender: string;
  address: any;
  city: any;
  country: any;
  jobTitle: any;
  bio: any;
  organizations: Organization[];
  primaryOrganization: PrimaryOrganization;
  projectDetails: ProjectDetails;
  dateModified: string;
  dateCreated: string;
}

export interface ProjectDetails {
  totalDonationsReceived: number;
}

export interface Organization {
  organizationId: number;
  name: string;
  isPrimary: boolean;
  verified: boolean;
  badges: Badge[];
}

export interface Badge {
  id: number;
  badgeId: number;
  title: string;
  imageUrl: string;
}

export interface PrimaryOrganization {
  organizationId: number;
  name: string;
  isPrimary: boolean;
  verified: boolean;
  badges: Badge[];
}

export interface Meta {
  total: number;
}

export interface Meta {
  total: number;
}
