import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddDashItemComponent } from "./add-dash-item/add-dash-item.component";
import { EditDashItemFormComponent } from "./edit-dash-item/edit-dash-item-form/edit-dash-item-form.component";
import { EditDashItemPrimaryImageComponent } from "./edit-dash-item/edit-dash-item-primary-image/edit-dash-item-primary-image.component";
import { EditDashItemSecondaryImagesComponent } from "./edit-dash-item/edit-dash-item-secondary-images/edit-dash-item-secondary-images.component";
import { EditDashItemComponent } from "./edit-dash-item/edit-dash-item.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { SwiperModule } from "swiper/angular";
import { SharedcomponentsModule } from "../sharedcomponents/sharedcomponents.module";
import { CustomCompModule } from "../custom-comp/custom-comp.module";
import { SafeSrcUrlDirective } from "src/app/directives/safe-src-url.directive";
import { SafeSrcUrlModule } from "src/app/directives/safe-src-url.module";
import { EditorModule } from "@tinymce/tinymce-angular";

@NgModule({
  declarations: [
    AddDashItemComponent,
    EditDashItemComponent,
    EditDashItemFormComponent,
    EditDashItemPrimaryImageComponent,
    EditDashItemSecondaryImagesComponent,
  ],
  imports: [
    IonicModule,
    RouterModule,
    CommonModule,
    SwiperModule,
    FormsModule,
    SharedcomponentsModule,
    ReactiveFormsModule,
    CustomCompModule,
    SafeSrcUrlModule,
    EditorModule,
  ],
  exports: [AddDashItemComponent, EditDashItemComponent],
})
export class DashitemsFormsModule {}
