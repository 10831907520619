/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { loadingRequest } from 'src/app/services/appconst';
import {
  AuthApiserviceService,
  IAvatarDpData,
} from 'src/app/services/authapiservice.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';

@Component({
  selector: 'app-profile-dp-avatar',
  templateUrl: './profile-dp-avatar.component.html',
  styleUrls: ['./profile-dp-avatar.component.scss'],
})
export class ProfileDpAvatarComponent implements OnInit {
  @Input() queryParams: any;
  @Output() onSelectImage: EventEmitter<any> = new EventEmitter();

  avatars: IAvatarDpData[] = [];

  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  loadingstate = loadingRequest.loading; // Set the Initial State to Loading

  constructor(
    public generalService: GeneralserviceService,
    public authService: AuthApiserviceService
  ) {}

  ngOnInit() {
    this.loadDpAvatar();
  }

  async loadDpAvatar() {
    this.loadingstate = loadingRequest.loading;

    await this.authService.onLoad();

    await this.authService
      .getAvatars({ perpage: 6 })
      .subscribe(
        (res) => {
          // console.log(res);
          if (!res.meta.total) {
            this.loadingstate = loadingRequest.noData;
          } else if (res.meta.total) {
            this.loadingstate = loadingRequest.success;
            this.avatars = res.data;
            // console.log(res);
          }
        },
        (err) => {
          this.loadingstate = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }

  selectImage(index) {
    this.onSelectImage.emit(this.avatars[index].imageUrl);
  }
}
