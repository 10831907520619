import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-landingprefooter-two",
  templateUrl: "./landingprefooter-two.component.html",
  styleUrls: ["./landingprefooter-two.component.scss"],
})
export class LandingprefooterTwoComponent implements OnInit {
  whyDash = [
    {
      title: "Seamless Giving Experience",
      caption:
        "Explore causes and give freely. We have simplified giving to help you fundraise effortlessly.",
    },
    {
      title: "Track your Giving",
      caption:
        "Stay informed with transaction records. Easily track your transfer history within your Dash account.",
    },
    {
      title: "Personalized Connections",
      caption:
        "Connect with people, embrace their stories, and nurture real connections.",
    },
    {
      title: "Impactful Causes",
      caption:
        "See your generosity in action with timely campaign updates, donation reports, and real results.",
    },
  ];

  constructor() {}

  ngOnInit() {}
}
