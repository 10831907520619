/* eslint-disable @typescript-eslint/no-shadow */
import { HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';
import { FundRaiserServiceService } from 'src/app/services/fund-raiser-service.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';

@Component({
  selector: 'app-edit-fundraiser-project-image',
  templateUrl: './edit-fundraiser-project-image.component.html',
  styleUrls: ['./edit-fundraiser-project-image.component.scss'],
})
export class EditFundraiserProjectImageComponent implements OnInit {
  @Input() img: string;
  @Input() id: string | number;
  returnimagefile = '';
  hasimage = false;
  formData = null;
  uploadProgress = 0;
  isUploading = false;

  constructor(
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService,
    public fundRaiseService: FundRaiserServiceService
  ) {}

  ngOnInit() {}

  pickpicture() {
    document.getElementById('picfromfile').click();
  }

  showpicture(event) {
    const fileList = event.target.files;
    const myFile = fileList[0];

    const myFileName = myFile.name;
    const myFileSize = myFile.size;
    const myFileType = myFile.type;

    if (myFileSize > 1024 * 5000) {
      alert('Your Image exceed 5mb');
      return;
    }

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      // This help us to read the file in Base 64 Format for Preview
      reader.onload = (event: any) => {
        this.img = event.target.result;
        this.hasimage = true;
        this.returnimagefile = event.target.result;
        this.img = this.returnimagefile;
        this.uploadDp();
      };
      reader.readAsDataURL(event.target.files[0]);
    }

    // Add the file to form data, which will be sent in the Upload Dp function
    this.formData = new FormData();
    Array.from(event.target.files).forEach((file: File) =>
      this.formData.append('photos', file, file.name)
    );
  }

   async clearFormData() {
    try {
      this.formData.delete('photos');
      this.formData.delete('id');
    } catch (err) {}
  }

  async uploadDp() {
    this.formData.append('id', this.id);
    this.isUploading = true;
    this.fundRaiseService
      .updateFundRaiserProjectImage(this.formData)
      .subscribe(
        (res) => {
          // console.log(res);
          if (res.type === HttpEventType.UploadProgress) {
            // Output the progress
            this.uploadProgress = Math.round((100 * res.loaded) / res.total);
          } else if (res.type === HttpEventType.Response) {
            // Once Upload is completed output 100%
            this.uploadProgress = 100;
            this.generalService.presentToast('Your Picture Has being updated');
          }
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || 'Unable to Load Profile'
          );
        }
      )
      .add(() => {
        this.isUploading = false;
        this.uploadProgress = 0;
        this.clearFormData();
      });
  }
}
