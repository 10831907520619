/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  AuthApiserviceService,
  IUserData,
} from "src/app/services/authapiservice.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import { environment } from "src/environments/environment";

import { IonModal } from "@ionic/angular";
import { OverlayEventDetail } from "@ionic/core/components";

@Component({
  selector: "app-signincomp",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements OnInit {
  authData: IUserData = {};
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;
  deviceSize;
  showBackMenu = false;
  isMobileApp = false;

  isModalOpen: boolean = false;
  modalIndex = 0;
  modalTitle: string = "";

  @Output() loginStatusCode: EventEmitter<any> = new EventEmitter();
  @Output() reDirectionPage: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {
    this.logout();
    this.onInit();
  }

  async onInit() {
    // this.authservice.signInRedirectPage =
    //   "/fundraiserdonation-me";
    this.deviceSize = await this.generalService.platformDimension();
    this.showBackMenu = !environment.mobileApp;
    this.isMobileApp = environment.mobileApp;
    // console.log(this.deviceSize)

    //Show Advert on Web
    this.isModalOpen = !environment.mobileApp;
  }

  ionViewDidEnter() {
    this.startproceessing = false;
    this.logout();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      passWord: ["", [Validators.required, Validators.minLength(8)]],
    });
  }

  async logout() {}

  async proceed() {
    if (!this.myForm.valid) {
      this.generalService.presentToast("Form not valid, check your form input");
      return;
    }

    this.startproceessing = true;

    this.authservice
      .login(this.myForm.value)
      .subscribe(
        (res) => {
          // console.log(JSON.stringify(res));
          this.authData = res;
          this.authservice.storeLoginToken(this.authData.data?.token);
          this.authservice.storeUserdetails(this.authData.data?.credentials);
          this.generalService.presentToast(
            "Login Successful",
            "",
            2000,
            "toast-custom-class-success"
          );
          this.popStatusCode(200);
          if (!this.authservice.signInRedirectPage) {
            this.navigate("dashboard");
            this.authservice.signInRedirectPage = null;
          } else {
            this.navigate(this.authservice.signInRedirectPage);
            this.authservice.signInRedirectPage = null;
          }
        },
        (err) => {
          // console.log(err);
          this.popStatusCode(err.status);
          this.generalService.presentToast(
            err.error?.message || "Error Connecting to server",
            "",
            5000,
            "toast-custom-class-error"
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }

  navigate(pagename) {
    this.reDirectionPage.emit(pagename);
  }

  popStatusCode(index) {
    this.loginStatusCode.emit(index);
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    // console.log(ev.detail);
    this.isModalOpen = false;
  }

  toogleModal(isOpen: boolean, title?: string, index?: number) {
    this.isModalOpen = isOpen;
    this.modalTitle = title;
    this.modalIndex = index;
  }
}
