/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { GeneralserviceService } from "src/app/services/generalservice.service";

import SwiperCore, {
  EffectFade,
  Autoplay,
  Keyboard,
  Pagination,
  Scrollbar,
  Zoom,
  Navigation,
  SwiperOptions,
  Swiper,
} from "swiper";
import { SwiperComponent } from "swiper/angular";

SwiperCore.use([
  EffectFade,
  Autoplay,
  Keyboard,
  Pagination,
  Scrollbar,
  Navigation,
  Zoom,
]);

@Component({
  selector: "app-dashboard-top-projects-cards-slides",
  templateUrl: "./dashboard-top-projects-cards-slides.component.html",
  styleUrls: ["./dashboard-top-projects-cards-slides.component.scss"],
})
export class DashboardTopProjectsCardsSlidesComponent implements OnInit {
  @Input() slideType: "default" | "landing" = "default";
  @ViewChild("swiperHomeSlide", { static: false }) swiper?: SwiperComponent;

  @Input() slides = [];

  config: SwiperOptions = {
    breakpoints: {
      320: { slidesPerView: 1 },
      640: { slidesPerView: 2 },
      900: { slidesPerView: 3 },
    },
    slidesPerView: 2,
    spaceBetween: 20,
    navigation: true,
    autoplay: {
      delay: 2900,
      disableOnInteraction: false,
    },
    pagination: { clickable: true },
  };

  constructor(public generalService: GeneralserviceService) {}

  ngOnInit() {
    // console.log(this.slideType);
    if (this.slideType === "landing") {
      this.config = {
        breakpoints: {
          320: { slidesPerView: 1 },
          640: { slidesPerView: 2 },
          900: { slidesPerView: 3 },
        },
        slidesPerView: 2,
        spaceBetween: 20,
        navigation: true,
        autoplay: {
          delay: 2900,
          disableOnInteraction: false,
        },
        pagination: { clickable: true },
      };
    }
  }

  onSwiper(swiper: Swiper) {
    setTimeout(() => {
      swiper.autoplay.start();
    }, 1000);
  }

  onSlideChange() {
    // console.log('slide change');
  }

  ngAfterViewInit(): void {
    // Start autoplay
    this.swiper.swiperRef.autoplay.start();
  }
}
