import { Component, OnInit } from '@angular/core';
import { loadingRequest } from 'src/app/services/appconst';
import { GeneralserviceService } from 'src/app/services/generalservice.service';
import {
  IteamData,
  TeamServiceService,
} from 'src/app/services/team-service.service';
import { editTeamDataProps } from '../edit-team-member/edit-team-member.component';
import { OverlayEventDetail } from "@ionic/core/components";

@Component({
  selector: "app-team-lists",
  templateUrl: "./team-lists.component.html",
  styleUrls: ["./team-lists.component.scss"],
})
export class TeamListsComponent implements OnInit {
  teamMembers: IteamData[] = [];
  showModal = false;
  ModalType = "";
  editTeamData: editTeamDataProps;
  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  loadingstate = loadingRequest.loading; // Set the Initial State to Loading

  isModalOpen: boolean = false;
  modalIndex = 0;
  modalTitle: string = "";

  constructor(
    public generalService: GeneralserviceService,
    public teamService: TeamServiceService
  ) {}

  ngOnInit() {
    this.loadTeams();
  }

  async loadTeams() {
    this.loadingstate = loadingRequest.loading;

    await this.teamService.authservice.onLoad();

    await this.teamService
      .getTeam({
        organizationid: this.teamService.authservice.activeOrganisation.id,
      })
      .subscribe(
        (res) => {
          // console.log(res.meta.total);
          if (!res.meta.total) {
            this.loadingstate = loadingRequest.noData;
          } else if (res.meta.total) {
            this.loadingstate = loadingRequest.success;
            this.teamMembers = res.data;
            // console.log(res);
          }
        },
        (err) => {
          this.loadingstate = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }

  toggleModal(modalType, index?) {
    this.showModal = !this.showModal;
    this.ModalType = modalType;

    if (modalType == "edit-team") {
      this.editTeamData = {
        id: this.teamMembers[index].organizationMemberId,
        role: this.teamMembers[index].role.toLocaleLowerCase(),
        email: this.teamMembers[index].user.email || "testemail@gmail.com",
      };
    }

    if (this.showModal == false) {
      this.loadTeams();
    }
  }

  deleteTeamMembers(index) {
    let cssClass = "toast-custom-class-success";

    this.generalService.presentBasicAlertDialog(
      "",
      "Delete",
      "Are you sure You want to Delete this Member",
      "Ok",
      "Cancel",
      () => {
        this.deleteConfirmed(this.teamMembers[index].organizationMemberId);
      },
      () => {
        this.generalService.presentToast(
          "Operation Cancel",
          "",
          2000,
          "toast-custom-class-error"
        );
      }
    );
  }

  deleteConfirmed(id) {
    this.teamService.deleteTeamMember({ id: id }).subscribe(
      (res) => {
        console.log(res);
        this.generalService.presentToast(
          "Delete Successful",
          "",
          2000,
          "toast-custom-class-success"
        );
        this.loadTeams();
      },
      (err) => {
        console.log(err);
        this.generalService.presentToast(
          err.error?.message || "Error Connecting to server",
          "",
          5000,
          "toast-custom-class-error"
        );
      }
    );
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    // console.log(ev.detail);
    this.isModalOpen = false;
  }

  toogleModal(isOpen: boolean, title?: string, index?: number) {
    this.isModalOpen = isOpen;
    this.modalTitle = title;
    this.modalIndex = index;

     if (title == "edit-team") {
       this.editTeamData = {
         id: this.teamMembers[index].organizationMemberId,
         role: this.teamMembers[index].role.toLocaleLowerCase(),
         email: this.teamMembers[index].user.email || "testemail@gmail.com",
       };
     }

     if (this.isModalOpen == false) {
       this.loadTeams();
     }
  }
}
