import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { RouterModule } from "@angular/router";
import { SidemenuComponent } from "./sidemenu/sidemenu.component";
import { LandingmenuComponent } from "./landingmenu/landingmenu.component";
import { LandingfooterComponent } from "./landingfooter/landingfooter.component";
import { LandingmenumobileComponent } from "./landingmenumobile/landingmenumobile.component";
import { ProfilecomponentsModule } from "../profilecomponents/profilecomponents.module";
import { DashboardMenuBarComponent } from "./dashboard-menu-bar/dashboard-menu-bar.component";
import { CreateCauseCompModule } from "./create-cause-comp/create-cause-comp.module";
import { FormsModule } from "@angular/forms";
import { LandingprefooterOneComponent } from "./landingprefooter-one/landingprefooter-one.component";
import { LandingprefooterTwoComponent } from "./landingprefooter-two/landingprefooter-two.component";
import { TabMenuComponent } from "./tab-menu/tab-menu.component";

@NgModule({
  declarations: [
    SidemenuComponent,
    LandingmenuComponent,
    LandingmenumobileComponent,
    LandingfooterComponent,
    DashboardMenuBarComponent,
    LandingprefooterOneComponent,
    LandingprefooterTwoComponent,
    TabMenuComponent,
  ],
  imports: [
    IonicModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ProfilecomponentsModule,
    CreateCauseCompModule,
  ],
  exports: [
    SidemenuComponent,
    LandingmenuComponent,
    LandingmenumobileComponent,
    LandingfooterComponent,
    DashboardMenuBarComponent,
    LandingprefooterOneComponent,
    LandingprefooterTwoComponent,
    TabMenuComponent,
  ],
})
export class LayoutModule {}
