import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";

@Component({
  selector: "app-custom-input-box",
  templateUrl: "./custom-input-box.component.html",
  styleUrls: ["./custom-input-box.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomInputBoxComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomInputBoxComponent),
      multi: true,
    },
  ],
})
export class CustomInputBoxComponent
  implements ControlValueAccessor, Validator, OnInit
{
  @ViewChild("inputText") inputText: ElementRef;

  // Props for Customisation
  @Input() iconType: "ionicon" | "image" | "none" = "none";
  @Input() ionIcon: string = "mail-outline";
  @Input() iconUrl: string = "assets/images/iconauthpic1.svg";
  @Input() type:
    | "text"
    | "password"
    | "number"
    | "date"
    | "datetime"
    | "datetime-local" = "text";
  @Input() placeHolder: string = "Enter Text";
  @Input() readonly: Boolean = false;
  @Input() alwayShowHeader: Boolean = false;
  @Input() hasError: Boolean = false;
  @Input() required: Boolean = true;

  // Props for NgModel
  @Input() customNgModal: string;
  @Output() customNgModalChange = new EventEmitter<string>();

  // Variable for Form Control
  value: string;
  disabled = false;
  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  //Variable for Customisation
  passwordVisible: Boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.value = this.customNgModal;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // Perform validation logic here
    if ((!control.value || control.value === "") && this.required) {
      return { required: true };
    }
    return null;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  modelChange($event) {
    this.customNgModalChange.emit($event.target.value);
  }

  onPasswordToogle() {
    this.passwordVisible = !this.passwordVisible;
  }

  setFocus() {
    setTimeout(() => {
      // this will make the execution after the above boolean has changed
      this.inputText.nativeElement.focus();
    }, 0);
  }
}
