import { Component, OnInit } from "@angular/core";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-dashboard-home-statitics",
  templateUrl: "./dashboard-home-statitics.component.html",
  styleUrls: ["./dashboard-home-statitics.component.scss"],
})
export class DashboardHomeStatiticsComponent implements OnInit {
  hideWalletBalance = true;
  isMobileApp = false;
  percentageVerify = 0;

  constructor(
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {
    console.log(this.authservice.activeOrganisation.verified);
    this.isMobileApp = environment.mobileApp;

    if (this.authservice?.activeOrganisation?.verified) {
      this.percentageVerify = this.percentageVerify + 50;
    }

    if (this.authservice?.userDetails?.emailVerified) {
      this.percentageVerify = this.percentageVerify + 50;
    }
  }

  toogleWalletBalance() {
    this.hideWalletBalance = !this.hideWalletBalance;
  }

  copyText() {
    // Get the text field
    const copyText: any = document.getElementById("linkReferralText");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    window.navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    this.generalService.presentToast("Referral Code Copied");
  }

  ngOnInit() {}
}
