import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-tab-menu",
  templateUrl: "./tab-menu.component.html",
  styleUrls: ["./tab-menu.component.scss"],
})
export class TabMenuComponent implements OnInit {
  tabMenuPages = [
    {
      title: "Home",
      url: "/dashboard",
      icon: "home-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
    },
    {
      title: "FundRaisers",
      url: "/fundraiserdonation-me",
      icon: "heart-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
    },
    {
      title: "Dash Stuffs",
      url: "/dashitems-me",
      icon: "cart-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
    },
    {
      title: "WishLists",
      url: "/wishlist-me",
      icon: "gift-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
    },
    {
      title: "Wallet",
      url: "/wallets",
      icon: "wallet-outline",
      imageurl: "assets/images/svg/home.svg",
      subtitle: "View Now",
      label: false,
    },
  ];

  pathname = "";

  constructor() {
    this.getpathname();
  }

  getpathname() {
    this.pathname = window.location.pathname;
    // console.log(this.pathname);
  }

  ngOnInit() {}
}
