import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { loadingRequest } from "src/app/services/appconst";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import {
  IWishListCategoryData,
  IwishListData,
  WishlistServiceService,
} from "src/app/services/wishlist-service.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-edit-wish-list-form",
  templateUrl: "./edit-wish-list-form.component.html",
  styleUrls: ["./edit-wish-list-form.component.scss"],
})
export class EditWishListFormComponent implements OnInit {
  @Input() wishList: IwishListData = null;

  categoryLoadingState = loadingRequest.idle;
  catogories: IWishListCategoryData[] = [];

  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  editorConfig = {
    menubar: false, // Set this to false to remove the menu
    plugins: ["image"],
    toolbar:
      "formatselect | bold italic underline | alignleft aligncenter alignright alignjustify| undo redo  | image | ",
    images_upload_url:
      environment.apiBaseEndPoint + "/api/v1/misc/tiny-cloud/save-image.ashx",
    // Add other configurations as needed
  };

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralserviceService,
    public wishListService: WishlistServiceService,
    public authservice: AuthApiserviceService
  ) {
    this.loadCategory();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      id: [this.wishList.wishlistId, [Validators.required]],
      title: [this.wishList.title, [Validators.required]],
      description: [this.wishList.description, [Validators.required]],
      wishlistCategoryId: [
        this.wishList.wishlistCategory.id,
        [Validators.required],
      ],
      startDate: [
        this.wishList.startDate
          ? this.generalService.utcToHtmlDate(this.wishList.startDate)
          : "",
        [Validators.required],
      ],
      endDate: [
        this.wishList.endDate
          ? this.generalService.utcToHtmlDate(this.wishList.endDate)
          : "",
        [Validators.required],
      ],
      isPrivate: this.wishList.isPrivate,
    });
  }

  async loadCategory() {
    this.categoryLoadingState = loadingRequest.loading;
    await this.wishListService
      .getWishListCategory({})
      .subscribe(
        (res) => {
          // console.log(res);
          this.catogories = res.data;
          this.categoryLoadingState = loadingRequest.success;
        },
        (err) => {
          this.categoryLoadingState = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }

  async proceed() {
    if (
      this.generalService.daysDifference(
        this.myForm.value?.startDate,
        this.myForm.value?.endDate
      ) < 0 ||
      this.generalService.daysDifference(
        this.myForm.value?.startDate,
        this.myForm.value?.endDate
      ) === NaN
    ) {
      this.generalService.presentToast(
        "First Date must be less than Second Date"
      );
      return;
    }

    if (
      this.generalService.daysDifference(null, this.myForm.value?.endDate) <
        0 ||
      this.generalService.daysDifference(null, this.myForm.value?.endDate) ===
        NaN
    ) {
      this.generalService.presentToast("End Date Must be A future Date");
      return;
    }

    // Get Value from the form in form of an object
    const formValue: any = this.myForm.value;
    // console.log(formValue);

    if (!this.myForm.valid) {
      this.generalService.presentToast("Form not valid, check your form input");
      return;
    }

    this.startproceessing = true;

    this.wishListService
      .updateWishList(formValue)
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast(
            "Your Wish List has being updated",
            "",
            2000,
            "toast-custom-class-success"
          );
          this.wishListService.onReloadTriggered.next(true);
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || "Error Connecting to server",
            "",
            5000,
            "toast-custom-class-error"
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }
}
