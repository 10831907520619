import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthApiserviceService } from './authapiservice.service';

const apiBaseEndPoint = environment.apiBaseEndPoint;

// const STAGING = 'https://stagingapi.idashyou.ng/';
// const REMOTE_HOST = 'https://stagingapi.idashyou.ng/';

// if (environment.production) {
//   apiBaseEndPoint = REMOTE_HOST;
// }

const refreshLoginRoute = `${apiBaseEndPoint}/api/v1/user/auth/sessions-login.ashx`;

const addTeamRoute = `${apiBaseEndPoint}/api/v1/user/organization-members/add-organization-member.ashx`;
const getTeamRoute = `${apiBaseEndPoint}//api/v1/user/organization-members/get-organization-members.ashx`;
const deleteTeamRoute = `${apiBaseEndPoint}/api/v1/user/organization-members/delete-organization-member.ashx`;
const updateTeamRoute = `${apiBaseEndPoint}//api/v1/user/organization-members/update-organization-member.ashx`;

@Injectable({
  providedIn: 'root',
})
export class TeamServiceService {
  constructor(
    private httpClient: HttpClient,
    public authservice: AuthApiserviceService
  ) {}

  getTeam(params: any): Observable<any> {
    return this.httpClient.get<any>(getTeamRoute, {
      headers: this.authservice.header,
      params,
    });
  }

  addTeamMember(body: any): Observable<any> {
    return this.httpClient.post<any>(addTeamRoute, body, {
      headers: this.authservice.header,
    });
  }

  editTeamRole(body: any): Observable<any> {
    return this.httpClient.post<any>(updateTeamRoute, body, {
      headers: this.authservice.header,
    });
  }

  deleteTeamMember(body: any): Observable<any> {
    return this.httpClient.post<any>(deleteTeamRoute, body, {
      headers: this.authservice.header,
    });
  }
}

export type ITeams = {
  message: string;
  data: IteamData[];
  meta: {
    total: number;
  };
};

export type IteamData = {
  organizationMemberId: string | number;
  organizationId: string | number;
  user: {
    userId: string | number;
    name: string;
    email?: string;
    invitesStatus: string;
  };
  role: string;
  dateModified: Date;
  dateCreated: Date;
};
