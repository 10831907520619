/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  AuthApiserviceService,
  IUserData,
} from "src/app/services/authapiservice.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-signupcomp",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  step = 0;
  authData: IUserData = {};
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;
  deviceSize;
  isMobileApp = false;

  @Output() loginStatusCode: EventEmitter<any> = new EventEmitter();
  @Output() reDirectionPage: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {
    this.logout();
    this.onInit();
  }

  async onInit() {
    this.deviceSize = await this.generalService.platformDimension();
    this.isMobileApp = environment.mobileApp;
    // console.log(this.deviceSize);
  }

  ionViewDidEnter() {
    this.startproceessing = false;
    this.logout();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      phoneNumber: ["", [Validators.required, Validators.minLength(11)]],
      city: ["", [Validators.required]],
      country: ["", [Validators.required]],
      passWord: ["", [Validators.required, Validators.minLength(8)]],
      confirmPassWord: ["", [Validators.required, Validators.minLength(8)]],
      gender: ["", [Validators.required]],
      isPublicOrganization: [""],
      organizationName: [""],
      referralCode: [""],
      dpUrl: [""],
    });
    this.disableSecondPageValidation();
  }

  get firstName() {
    return this.myForm.get("firstName") as FormControl;
  }

  get lastName() {
    return this.myForm.get("lastName") as FormControl;
  }

  get phoneNumber() {
    return this.myForm.get("phoneNumber") as FormControl;
  }

  get country() {
    return this.myForm.get("country") as FormControl;
  }

  get city() {
    return this.myForm.get("city") as FormControl;
  }

  get gender() {
    return this.myForm.get("gender") as FormControl;
  }

  disableSecondPageValidation() {
    this.firstName.disable();
    this.lastName.disable();
    this.phoneNumber.disable();
    this.country.disable();
    this.city.disable();
    this.gender.disable();
  }

  enableSecondPageValidation() {
    this.firstName.enable();
    this.lastName.enable();
    this.phoneNumber.enable();
    this.country.enable();
    this.city.enable();
    this.gender.enable();
  }

  async logout() {}

  goBackward() {
    if (this.step === 0) {
      this.navigate("login");
    } else if (this.step === 1) {
      this.disableSecondPageValidation();
      this.step = 0;
    }
  }

  gotoForward() {
    if (this.step === 0) {
      this.startproceessing = true;
      setTimeout(() => {
        this.enableSecondPageValidation();
        this.step = 1;
        this.startproceessing = false;
      }, 1200);
    } else if (this.step === 1) {
      this.proceed();
    }
  }

  async proceed() {
    // Get Value from the form in form of an object
    const formValue: any = this.myForm.value;

    // Remove the Confirm Password Property is not necessary
    delete formValue.confirmPassWord;

    // Add OtherNames Property it was not collected but needed in our Sign Up Body.
    formValue.otherNames = "";

    if (!this.myForm.valid) {
      this.generalService.presentToast("Form not valid, check your form input");
      return;
    }

    this.startproceessing = true;

    this.authservice
      .signUp(formValue)
      .subscribe(
        (res) => {
          // console.log(res);
          this.authData = res;
          this.authservice.storeLoginToken(this.authData.data?.token);
          this.authservice.storeUserdetails(this.authData.data?.credentials);
          this.generalService.presentToast("Your Account Has being created");
          this.popStatusCode(200);
          this.navigate("dashboard");
        },
        (err) => {
          // console.log(err);
          this.popStatusCode(err.status);
          this.generalService.presentToast(err.error?.message);
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }

  navigate(pagename) {
    this.reDirectionPage.emit(pagename);
  }

  popStatusCode(index) {
    this.loginStatusCode.emit(index);
  }

  updateDpUrl($event) {
    // console.log($event);
    this.myForm.patchValue({
      dpUrl: $event,
    });
    // console.log(this.myForm.value);
  }
}
