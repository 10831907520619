import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';
import { TeamServiceService } from 'src/app/services/team-service.service';

@Component({
  selector: 'app-add-team-member',
  templateUrl: './add-team-member.component.html',
  styleUrls: ['./add-team-member.component.scss'],
})
export class AddTeamMemberComponent implements OnInit {
  roles = ['admin', 'fundraiser', 'member'];

  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralserviceService,
    public teamService: TeamServiceService,
    public authservice: AuthApiserviceService
  ) {}

  ngOnInit() {
    this.myForm = this.fb.group({
      organizationId: [''],
      userEmail: ['', [Validators.required, Validators.email]],
      role: ['', [Validators.required]],
    });
    this.getUpdateData();
  }

  async getUpdateData() {
    await this.authservice.onLoad();
    await this.myForm.setValue({
      ...this.myForm.value,
      organizationId: this.authservice.userDetails.organizations[0].id,
    });
  }

  async proceed() {
    // Get Value from the form in form of an object
    let formValue: any = this.myForm.value;
    // console.log(formValue);

    if (!this.myForm.valid) {
      this.generalService.presentToast('Form not valid, check your form input');
      return;
    }

    this.startproceessing = true;

    this.teamService
      .addTeamMember(formValue)
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast(
            'Your Invitation has being successfully made',
            '',
            2000,
            'toast-custom-class-success'
          );
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || 'Error Connecting to server',
            '',
            5000,
            'toast-custom-class-error'
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }
}
