import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthApiserviceService } from "./authapiservice.service";

const apiBaseEndPoint = environment.apiBaseEndPoint;

// const STAGING = 'https://stagingapi.idashyou.ng/';
// const REMOTE_HOST = 'https://stagingapi.idashyou.ng/';

// if (environment.production) {
//   apiBaseEndPoint = REMOTE_HOST;
// }

const addWishList = `${apiBaseEndPoint}/api/v1/user/wishlists/add-wishlist.ashx`;
const addWishListItem = `${apiBaseEndPoint}api/v1/user/wishlist-items/add-wishlist-item.ashx`;
const addWishListItemImage = `${apiBaseEndPoint}/api/v1/user/user-wishlist-item-images/add-image.ashx`;

const deleteWishList = `${apiBaseEndPoint}api/v1/user/wishlists/delete-wishlist.ashx`;
const deleteWishListItem = `${apiBaseEndPoint}api/v1/user/wishlist-items/delete-wishlist-item.ashx`;
const deleteWishListItemImage = `${apiBaseEndPoint}/api/v1/user/user-wishlist-item-images/delete-image.ashx`;

const withDrawWishList = `${apiBaseEndPoint}api/v1/user/wishlists/withdraw-wishlist.ashx`;

const updateWishList = `${apiBaseEndPoint}api/v1/user/wishlists/update-wishlist.ashx`;
const updateWishListImage = `${apiBaseEndPoint}api/v1/user/wishlists/update-wishlist-image.ashx`;
const updateWishListItem = `${apiBaseEndPoint}/api/v1/user/wishlist-items/update-wishlist-item.ashx`;
const updateWishListItemImage = `${apiBaseEndPoint}api/v1/user/wishlist-items/update-wishlist-item-image.ashx`;

const replayWishList = `${apiBaseEndPoint}api/v1/user/wishlists/reply-wishlist.ashx`;
const addWishListDonation = `${apiBaseEndPoint}api/v1/user/wishlist-donations/add-wishlist-donation.ashx`;

const getWishList = `${apiBaseEndPoint}/api/v1/user/wishlists/get-wishlists.ashx`;
const getWishListExplore = `${apiBaseEndPoint}/api/v1/user/wishlists/explore-wishlists.ashx`;
const getWishListSummary = `${apiBaseEndPoint}/api/v1/user/wishlists/get-wishlist-summary.ashx`;
const getWishListDonations = `${apiBaseEndPoint}/api/v1/user/wishlist-donations/get-wishlist-donations.ashx`;
const getWishListItems = `${apiBaseEndPoint}/api/v1/user/wishlist-items/get-wishlist-items.ashx`;
const getWishListCategory = `${apiBaseEndPoint}/api/v1/user/wishlist-categories/get-wishlist-categories.ashx`;
const getWishListItemImage = `${apiBaseEndPoint}/api/v1/user/user-wishlist-item-images/get-images.ashx`;

@Injectable({
  providedIn: "root",
})
export class WishlistServiceService {
  selectedWishList: IwishListData = null;
  onWishListItemsEdited: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onReloadTriggered: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    public authservice: AuthApiserviceService
  ) {}

  //#region  Add Region
  addWishList(body: any): Observable<any> {
    return this.httpClient.post<any>(addWishList, body, {
      headers: this.authservice.header,
    });
  }

  addWishListItem(body: any): Observable<any> {
    return this.httpClient.post<any>(addWishListItem, body, {
      headers: this.authservice.header,
    });
  }

  addWishListItemImage(body: any): Observable<any> {
    return this.httpClient.post<any>(addWishListItemImage, body, {
      headers: this.authservice.header,
    });
  }

  replayWishList(body: any): Observable<any> {
    return this.httpClient.post<any>(replayWishList, body, {
      headers: this.authservice.header,
    });
  }

  addWishListDonation(body: any): Observable<any> {
    return this.httpClient.post<any>(addWishListDonation, body, {
      headers: this.authservice.header,
    });
  }

  //#region Delete Region
  deleteWishList(body: any): Observable<any> {
    return this.httpClient.post<any>(deleteWishList, body, {
      headers: this.authservice.header,
    });
  }

  deleteWishListItem(body: any): Observable<any> {
    return this.httpClient.post<any>(deleteWishListItem, body, {
      headers: this.authservice.header,
    });
  }

  deleteWishListItemImage(body: any): Observable<any> {
    return this.httpClient.post<any>(deleteWishListItemImage, body, {
      headers: this.authservice.header,
    });
  }

  withDrawWishList(body: any): Observable<any> {
    return this.httpClient.post<any>(withDrawWishList, body, {
      headers: this.authservice.header,
    });
  }

  //#region  Update Region
  updateWishList(body: any): Observable<any> {
    return this.httpClient.post<any>(updateWishList, body, {
      headers: this.authservice.header,
    });
  }

  updateWishListItem(body: any): Observable<any> {
    return this.httpClient.post<any>(updateWishListItem, body, {
      headers: this.authservice.header,
    });
  }

  updateWishListImage(body: any): Observable<any> {
    return this.httpClient.post<any>(updateWishListImage, body, {
      headers: this.authservice.header,
      responseType: "json",
      reportProgress: true, // this is used to show the progress of upload
      observe: "events", // this will help to print the refresh all observable including the progress
    });
  }

  updateWishListItemImage(body: any): Observable<any> {
    return this.httpClient.post<any>(updateWishListItemImage, body, {
      headers: this.authservice.header,
    });
  }

  //#region  Get Region
  getWishList(params: any): Observable<IwishList> {
    return this.httpClient.get<IwishList>(getWishList, {
      headers: this.authservice.header,
      params,
    });
  }

  getWishListExplore(params: any): Observable<IwishList> {
    return this.httpClient.get<IwishList>(getWishListExplore, {
      headers: this.authservice.header,
      params,
    });
  }

  getWishListSummary(params: any): Observable<IWishlistSummary> {
    return this.httpClient.get<IWishlistSummary>(getWishListSummary, {
      headers: this.authservice.header,
      params,
    });
  }

  getWishListItems(params: any): Observable<any> {
    return this.httpClient.get<any>(getWishListItems, {
      headers: this.authservice.header,
      params,
    });
  }

  getWishListItemsImage(params: any): Observable<any> {
    return this.httpClient.get<any>(getWishListItemImage, {
      headers: this.authservice.header,
      params,
    });
  }

  getWishListCategory(params: any): Observable<IWishListCategory> {
    return this.httpClient.get<IWishListCategory>(getWishListCategory, {
      headers: this.authservice.header,
      params,
    });
  }

  getWishListDonation(params: any): Observable<any> {
    return this.httpClient.get<any>(getWishListDonations, {
      headers: this.authservice.header,
      params,
    });
  }
}

// Type

// Type For WishList Category
export interface IWishListCategory {
  message: string;
  data: IWishListCategoryData[];
  meta: Meta;
}

export interface IWishListCategoryData {
  wishlistCategoryId: string;
  title: string;
  dateModified: string;
  dateCreated: string;
}

// Type For WishList
export interface IwishList {
  message: string;
  data: IwishListData[];
  meta: Meta;
}

export interface IwishListData {
  wishlistId: number;
  organization: Organization;
  title: string;
  description: string;
  customUrl: string;
  imageUrl: string;
  items?: IWishListItem[];
  wishlistCategory: WishlistCategory;
  startDate: string;
  endDate?: string;
  amountDonated?: number;
  amountWithdrawable?: number;
  amountWithdrawn?: any;
  statusId: number;
  status: string;
  active: boolean;
  withdrawn: boolean;
  durationExpired: boolean;
  isPrivate: boolean;
  dateModified: string;
  dateCreated: string;
}

export interface Organization {
  id: number;
  name: string;
  verified: boolean;
  ownerId: number;
  badges: Badge[];
  ownerDpurl?: string;
  owner?: string;
  user?: User;
}

export interface Badge {
  id: number;
  badgeId: number;
  title: string;
  imageUrl: any;
}

export interface User {
  name: string;
  id: number;
  fullName: string;
  username: string;
  email: string;
  dpUrl: string;
}

export interface IWishListItem {
  id: string;
  name: string;
  price: number;
  imageUrl: string;
}

export interface WishlistCategory {
  id?: number;
  title?: string;
}

export interface Meta {
  total: number;
}

//Type for wishlistItems Icons
export interface IWishlistItemIcons {
  message: string;
  data: IWishlistItemIconsData[];
  meta: Meta;
}

export interface IWishlistItemIconsData {
  userWishlistItemImageId: string;
  userId: string;
  imageUrl: string;
  dateModified: string;
  dateCreated: string;
}

export interface Meta {
  total: number;
}

//Wishlist summary
export interface IWishlistSummary {
  message: string;
  data: IWishlistSummaryData;
}

export interface IWishlistSummaryData {
  noOfActiveWishlists: number;
  responsesReceived: number;
  responsesSent: number;
}

// WishList Donation

export interface IWishlistDonation {
  message: string;
  data: IWishlistDonationData[];
  meta: Meta;
}

export interface IWishlistDonationData {
  wishlistDonationId: number;
  wishlistId: number;
  wishlist: Wishlist;
  verified: boolean;
  amount: number;
  amountPaid: any;
  reference: any;
  message: string;
  anonymous: boolean;
  recipient: Recipient;
  donor: Donor;
  currency: any;
  paymentGateway: any;
  paymentReceivedAt: any;
  dateModified: string;
  dateCreated: string;
}

export interface Wishlist {
  id: number;
  title: string;
}

export interface Recipient {
  userId: string;
  email: any;
  fullName: string;
  userName?: string;
  organizationId: number;
  organizationName: string;
}

export interface Donor {
  userId: string;
  email: string;
  fullName: string;
  userName?: string;
  organizationId: number;
  organizationName: string;
}

export interface Meta {
  total: number;
}
