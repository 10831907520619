import { Component, Input, OnInit } from "@angular/core";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";

@Component({
  selector: "app-viewprofile-card-explore",
  templateUrl: "./viewprofile-card-explore.component.html",
  styleUrls: ["./viewprofile-card-explore.component.scss"],
})
export class ViewprofileCardExploreComponent implements OnInit {
  @Input() hideDetailsMobile = false;
  constructor(
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {
    this.refreshLocalUserDetails();
  }

  ngOnInit() {}

  async refreshLocalUserDetails() {
    await this.authservice.onLoad();
    // console.log(this.authservice.activeOrganisation.name);
  }
}
