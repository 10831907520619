import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import { OrganisationsServiceService } from "src/app/services/organisations-service.service";
import { AddFundraiserProjectService } from "../services/add-fundraiser-project.service";
import {
  FundRaiserServiceService,
  IProjectCategoryData,
} from "src/app/services/fund-raiser-service.service";
import { loadingRequest } from "src/app/services/appconst";

@Component({
  selector: "app-add-fundraiser-project-step-one",
  templateUrl: "./add-fundraiser-project-step-one.component.html",
  styleUrls: ["./add-fundraiser-project-step-one.component.scss"],
})
export class AddFundraiserProjectStepOneComponent implements OnInit {
  categoryLoadingState = loadingRequest.idle;
  catogories: IProjectCategoryData[] = [];

  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralserviceService,
    public organisationService: OrganisationsServiceService,
    public authservice: AuthApiserviceService,
    public addFundRaiserService: AddFundraiserProjectService,
    public fundRaiseService: FundRaiserServiceService
  ) {
    this.loadCategory();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      title: ["", [Validators.required]],
      projectcategoryid: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
    });
  }

  async loadCategory() {
    this.categoryLoadingState = loadingRequest.loading;
    await this.fundRaiseService
      .getFundRaiserCategory({})
      .subscribe(
        (res) => {
          // console.log(res);
          this.catogories = res.data;
          this.categoryLoadingState = loadingRequest.success;
        },
        (err) => {
          this.categoryLoadingState = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }

  async proceed() {
    // Get Value from the form in form of an object
    const formValue: any = this.myForm.value;
    // console.log(formValue);

    if (
      this.generalService.daysDifference(
        formValue?.startDate,
        formValue?.endDate
      ) < 0 ||
      this.generalService.daysDifference(
        formValue?.startDate,
        formValue?.endDate
      ) === NaN
    ) {
      this.generalService.presentToast(
        "First Date must be less than Second Date"
      );
      return;
    }

    if (
      this.generalService.daysDifference(null, formValue?.endDate) < 0 ||
      this.generalService.daysDifference(null, formValue?.endDate) === NaN
    ) {
      this.generalService.presentToast("End Date Must be A future Date");
      return;
    }

    if (!this.myForm.valid) {
      this.generalService.presentToast("Form not valid, check your form input");
      return;
    }

    this.addFundRaiserService.formValue = { ...formValue };
    this.addFundRaiserService.stepLevel = 2;
  }
}
