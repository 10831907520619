import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { SetnewpasswordComponent } from './setnewpassword/setnewpassword.component';
import { AuthmodalComponent } from './authmodal/authmodal.component';
import { ProfilecomponentsModule } from '../profilecomponents/profilecomponents.module';
import { AuthDisplaySliderComponent } from './auth-display-slider/auth-display-slider.component';
import { SwiperModule } from 'swiper/angular';
import { CustomCompModule } from '../custom-comp/custom-comp.module';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';

@NgModule({
  declarations: [
    ResetpasswordComponent,
    SigninComponent,
    SignupComponent,
    SetnewpasswordComponent,
    AuthmodalComponent,
    ConfirmEmailComponent,
    AuthDisplaySliderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    ProfilecomponentsModule,
    SwiperModule,
    CustomCompModule,
  ],
  exports: [
    ResetpasswordComponent,
    SigninComponent,
    SignupComponent,
    SetnewpasswordComponent,
    AuthmodalComponent,
    ConfirmEmailComponent,
    AuthDisplaySliderComponent,
  ],
})
export class AuthCompModule {}
