import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import { OverlayEventDetail } from "@ionic/core/components";
import { loadingRequest } from "src/app/services/appconst";
import {
  IWishListCategoryData,
  WishlistServiceService,
} from "src/app/services/wishlist-service.service";
import { HttpEventType } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-add-wish-list",
  templateUrl: "./add-wish-list.component.html",
  styleUrls: ["./add-wish-list.component.scss"],
})
export class AddWishListComponent implements OnInit {
  imagePreviewURL = "assets/images/add-image.png";
  formData = new FormData();

  categoryLoadingState = loadingRequest.idle;
  catogories: IWishListCategoryData[] = [];

  isModalOpen = false;
  modalTitle = "Title";
  modalIndex = 0;
  modalTab = 0;

  presetsItems = [];
  selectedPresetItem: any = "";
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;
  formProcessSuccess = false;
  generatedUrl = "";

  editorConfig = {
    menubar: false, // Set this to false to remove the menu
    plugins: ["image"],
    toolbar:
      "formatselect | bold italic underline | alignleft aligncenter alignright alignjustify| undo redo  | image | ",
    images_upload_url:
      environment.apiBaseEndPoint + "/api/v1/misc/tiny-cloud/save-image.ashx",
    // Add other configurations as needed
  };

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralserviceService,
    public wishListService: WishlistServiceService,
    public authservice: AuthApiserviceService
  ) {
    this.loadCategory();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      title: ["", [Validators.required]],
      description: ["", [Validators.required]],
      wishlistcategoryid: ["", [Validators.required]],
      items: this.fb.array([]),
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      listItems: [""],
      imageUrl: [""],
      isPrivate: false,
      photo: ["", [Validators.required]],
    });
  }

  async loadCategory() {
    this.categoryLoadingState = loadingRequest.loading;
    await this.wishListService
      .getWishListCategory({})
      .subscribe(
        (res) => {
          // console.log(res);
          this.catogories = res.data;
          this.categoryLoadingState = loadingRequest.success;
        },
        (err) => {
          this.categoryLoadingState = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }

  get items(): FormArray {
    return this.myForm.get("items") as FormArray;
  }

  get itemsControls(): FormGroup[] {
    return this.items.controls as FormGroup[];
  }

  pickpicture() {
    document.getElementById("picFeatureImage").click();
  }

  // Image Preview
  showPreview($event) {
    const file = ($event.target as HTMLInputElement).files[0];

    // Only use for varification the form data will send this
    this.myForm.patchValue({
      photo: file,
    });
    this.myForm.get("photo").updateValueAndValidity();

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreviewURL = reader.result as string;
      // console.log(this.addFundRaiserService.imagePreviewURL);
    };
    reader.readAsDataURL(file);

    if (this.formData.has("photo")) {
      this.formData.delete("photo");
    }
    this.formData.append("photo", file, file.name);
  }

  addItem() {
    if (this.selectedPresetItem === "") {
      return;
    }
    const newform = this.fb.group({
      name: [this.selectedPresetItem, Validators.required],
      price: [0, Validators.required],
      imageUrl: ["https://cdn-icons-png.flaticon.com/512/428/428001.png"],
    });

    this.items.push(newform);
    this.selectedPresetItem = "";
    // console.log(this.itemsControls);
  }

  removeItem(i: number) {
    this.items.removeAt(i);
  }

  async uploadPicture() {
    if (
      this.generalService.daysDifference(
        this.myForm.value?.startDate,
        this.myForm.value?.endDate
      ) < 0 ||
      this.generalService.daysDifference(
        this.myForm.value?.startDate,
        this.myForm.value?.endDate
      ) === NaN
    ) {
      this.generalService.presentToast(
        "First Date must be less than Second Date"
      );
      return;
    }

    if (
      this.generalService.daysDifference(null, this.myForm.value?.endDate) <
        0 ||
      this.generalService.daysDifference(null, this.myForm.value?.endDate) ===
        NaN
    ) {
      this.generalService.presentToast("End Date Must be A future Date");
      return;
    }

    this.startproceessing = true;

    this.wishListService
      .updateWishListImage(this.formData)
      .subscribe(
        (res) => {
          if (res.type === HttpEventType.UploadProgress) {
            // Track Image Uplad Status Here
          } else if (res.type === HttpEventType.Response) {
            // console.log(res);
            this.myForm.patchValue({
              imageUrl: res.body.imageUrl,
            });
            this.myForm.get("imageUrl").updateValueAndValidity();
            this.proceed();
          }
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || "Error Connecting to server",
            "",
            5000,
            "toast-custom-class-error"
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }

  async proceed() {
    this.startproceessing = true;

    // Get Value from the form in form of an object
    const formValue: any = this.myForm.value;

    // Remove the Photo as it has being sent via form data Property is not necessary
    delete formValue.photo;

    // console.log(formValue);

    if (!this.myForm.valid) {
      this.generalService.presentToast("Form not valid, check your form input");
      return;
    }

    let successText =
      "Your Dash Wish List Has Being Added, It will take some hours before it will reflect.";

    if (!this.wishListService.authservice.activeOrganisation?.verified) {
      successText =
        "Your Dash Wish List Has Being Added, It won't go public until you verify your Account. Go to your profile to verify your account";
    }

    this.startproceessing = true;

    this.wishListService
      .addWishList(formValue)
      .subscribe(
        (res) => {
          // console.log(res);
          this.myForm.reset();
          this.generalService.presentToast(
            "Your Dash Wish List Has Being Added",
            "",
            2000,
            "toast-custom-class-success"
          );
          this.generatedUrl = `${environment.appUrl}wishlist-details-site/${res?.data?.customUrl}/${res?.data.title}`;
          // console.log(this.generatedId);
          this.formProcessSuccess = true;
          this.wishListService.onReloadTriggered.next(true);
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || "Error Connecting to server",
            "",
            5000,
            "toast-custom-class-error"
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    // console.log(ev.detail);
    this.isModalOpen = false;
  }

  toogleModal(isOpen: boolean, title?: string, index?: number) {
    this.isModalOpen = isOpen;
    this.modalTitle = title;
    this.modalIndex = index;
  }

  changeModalTab(index) {
    this.modalTab = index;
  }

  updateIconImage($event) {
    this.itemsControls[this.modalIndex].patchValue({
      imageUrl: $event,
    });
    this.isModalOpen = false;
  }
}
