import { Component, Input, OnInit } from '@angular/core';
import { loadingRequest } from 'src/app/services/appconst';
import { GeneralserviceService } from 'src/app/services/generalservice.service';
import {
  IWishlistDonationData,
  WishlistServiceService,
} from 'src/app/services/wishlist-service.service';

@Component({
  selector: 'app-wishlist-activities-list',
  templateUrl: './wishlist-list.component.html',
  styleUrls: ['./wishlist-list.component.scss'],
})
export class WishlistListActivitesComponent implements OnInit {
  @Input() queryParams: any = {};

  wishList: IWishlistDonationData[];

  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  loadingstate = loadingRequest.loading; // Set the Initial State to Loading

  constructor(
    public generalService: GeneralserviceService,
    public wishListService: WishlistServiceService
  ) {}

  ngOnInit() {
    this.loadWishList();
  }

  async loadWishList() {
    this.loadingstate = loadingRequest.loading;

    await this.wishListService.authservice.onLoad();

    await this.wishListService
      .getWishListDonation({
        organizationid: this.wishListService.authservice.activeOrganisation?.id,
        donororgid: this.wishListService.authservice.activeOrganisation?.id,
        verified: 'true',
        perpage: 24,
        ...this.queryParams,
      })
      .subscribe(
        (res) => {
          if (!res.meta.total) {
            this.loadingstate = loadingRequest.noData;
          } else if (res.meta.total) {
            this.loadingstate = loadingRequest.success;
            this.wishList = res.data;
            // console.log(res);
          }
        },
        (err) => {
          this.loadingstate = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }
}
