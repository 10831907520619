import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthApiserviceService } from "./authapiservice.service";

const apiBaseEndPoint = environment.apiBaseEndPoint;

// const STAGING = 'https://stagingapi.idashyou.ng/';
// const REMOTE_HOST = 'https://stagingapi.idashyou.ng/';

// if (environment.production) {
//   apiBaseEndPoint = REMOTE_HOST;
// }

const addDashItem = `${apiBaseEndPoint}/api/v1/user/items/add-item.ashx`;
const addDashItemImage = `${apiBaseEndPoint}/api/v1/user/item-images/add-item-image.ashx`;
const addDashItemProposal = `${apiBaseEndPoint}/api/v1/user/item-proposals/add-item-proposal.ashx`;

const updateDashItem = `${apiBaseEndPoint}/api/v1/user/items/update-item.ashx`;
const updateDashItemImage = `${apiBaseEndPoint}/api/v1/user/items/update-item-image.ashx`;

const updateDashItemProposal = `${apiBaseEndPoint}/api/v1/user/item-proposals/update-item-proposal.ashx`;
const updateDashItemProposalImage = `${apiBaseEndPoint}/api/v1/user/item-proposals/update-item-proposal-image.ashx`;
const updateDashItemProposalVideo = `${apiBaseEndPoint}/api/v1/user/item-proposals/update-item-proposal-video.ashx`;
const updateDashItemStatus = `${apiBaseEndPoint}/api/v1/user/item-proposals/update-status.ashx`;

const endDashItem = `${apiBaseEndPoint}/api/v1/user/items/reject-proposal.ashx`;
const deleteDashItem = `${apiBaseEndPoint}/api/v1/user/items/delete-item.ashx`;
const deleteDashItemImage = `${apiBaseEndPoint}/api/v1/user/item-images/delete-item-image.ashx`;

const getDashItemCateory = `${apiBaseEndPoint}/api/v1/user/item-categories/get-item-categories.ashx`;
const getDashItemProposal = `${apiBaseEndPoint}/api/v1/user/item-proposals/get-item-proposals.ashx`;
const getDashItemApi = `${apiBaseEndPoint}/api/v1/user/items/get-items.ashx`;
const getExploreDashItemApi = `${apiBaseEndPoint}/api/v1/user/items/explore-items.ashx`;
const getDashItemSummary = `${apiBaseEndPoint}/api/v1/user/items/get-item-summary.ashx`;

@Injectable({
  providedIn: "root",
})
export class DashitemServiceService {
  selectedDashItem: IDashItemData = null;
  onReloadTriggered: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    public authservice: AuthApiserviceService
  ) {}

  //#region  Get Region
  getDashItem(params: any): Observable<IDashItem> {
    return this.httpClient.get<IDashItem>(getDashItemApi, {
      headers: this.authservice.header,
      params,
    });
  }

  getExploreDashItem(params: any): Observable<IDashItem> {
    return this.httpClient.get<IDashItem>(getExploreDashItemApi, {
      headers: this.authservice.header,
      params,
    });
  }

  getDashItemSummary(params: any): Observable<IDashItemSumary> {
    return this.httpClient.get<IDashItemSumary>(getDashItemSummary, {
      headers: this.authservice.header,
      params,
    });
  }

  getDashItemCategory(params: any): Observable<IDashItemCategory> {
    return this.httpClient.get<IDashItemCategory>(getDashItemCateory, {
      headers: this.authservice.header,
      params,
    });
  }

  getDashItemProposal(params: any): Observable<any> {
    return this.httpClient.get<any>(getDashItemProposal, {
      headers: this.authservice.header,
      params,
    });
  }

  //#region  Add Region

  addDashItem(body: any): Observable<any> {
    return this.httpClient.post<any>(addDashItem, body, {
      headers: this.authservice.header,
    });
  }

  addDashItemImage(body: any): Observable<any> {
    return this.httpClient.post<any>(addDashItemImage, body, {
      headers: this.authservice.header,
      responseType: "json",
      reportProgress: true, // this is used to show the progress of upload
      observe: "events", // this will help to print the refresh all observable including the progress
    });
  }

  addDashItemProposal(body: any): Observable<any> {
    return this.httpClient.post<any>(addDashItemProposal, body, {
      headers: this.authservice.header,
    });
  }

  //#region  Update Region

  updateDashItem(body: any): Observable<any> {
    return this.httpClient.post<any>(updateDashItem, body, {
      headers: this.authservice.header,
    });
  }

  updateDashItemImage(body: any): Observable<any> {
    return this.httpClient.post<any>(updateDashItemImage, body, {
      headers: this.authservice.header,
      responseType: "json",
      reportProgress: true, // this is used to show the progress of upload
      observe: "events", // this will help to print the refresh all observable including the progress
    });
  }

  updateDashItemProposal(body: any): Observable<any> {
    return this.httpClient.post<any>(updateDashItemProposal, body, {
      headers: this.authservice.header,
    });
  }

  updateDashItemProposalImage(body: any): Observable<any> {
    return this.httpClient.post<any>(updateDashItemProposalImage, body, {
      headers: this.authservice.header,
    });
  }

  updateDashItemStatus(body: any): Observable<any> {
    return this.httpClient.post<any>(updateDashItemStatus, body, {
      headers: this.authservice.header,
    });
  }

  updateDashItemProposalVideo(body: any): Observable<any> {
    return this.httpClient.post<any>(updateDashItemProposalVideo, body, {
      headers: this.authservice.header,
    });
  }

  //#region Delete Region

  endDashItem(body: any): Observable<any> {
    return this.httpClient.post<any>(endDashItem, body, {
      headers: this.authservice.header,
    });
  }

  deleteDashItem(body: any): Observable<any> {
    return this.httpClient.post<any>(deleteDashItem, body, {
      headers: this.authservice.header,
    });
  }

  deleteDashItemImage(body: any): Observable<any> {
    return this.httpClient.post<any>(deleteDashItemImage, body, {
      headers: this.authservice.header,
    });
  }
}

// Type

export interface IDashItemCategory {
  message: string;
  data: IDashItemCategoryData[];
  meta: Meta;
}

export interface IDashItemCategoryData {
  itemCategoryId: string;
  title: string;
  dateModified: string;
  dateCreated: string;
}

export interface Meta {
  total: number;
}

// Dash Item

export interface IDashItem {
  message: string;
  data: IDashItemData[];
  meta: Meta;
}

export interface IDashItemData {
  itemId: string;
  organization: Organization;
  title: string;
  description: string;
  imageUrl: string;
  images?: Image[];
  itemCategory: ItemCategory;
  noOfProposals: number;
  itemName: any;
  noOfItems: number;
  customUrl: string;
  statusId: number;
  status: string;
  active: boolean;
  turnDownProposal: boolean;
  isPrivate: boolean;
  dateModified: string;
  dateCreated: string;
}

export interface Organization {
  id: number;
  name: string;
  verified: boolean;
  ownerId: number;
  badges: Badge[];
  ownerDpurl?: string;
  owner?: string;
  user?: User;
}

export interface Badge {
  id: number;
  badgeId: number;
  title: string;
  imageUrl: any;
}

export interface User {
  name: string;
  id: number;
  fullName: string;
  username: string;
  email: string;
  dpUrl: string;
}

export interface Image {
  id: string;
  image: string;
}

export interface ItemCategory {
  id: number;
  name: string;
}

export interface Meta {
  total: number;
}

// Dash Item summary
export interface IDashItemSumary {
  message: string;
  data: IDashItemSumaryData;
}

export interface IDashItemSumaryData {
  noOfItems: number;
  noOfProposalsSent: number;
  noOfProposalsReceived: number;
}

// Dash Item Proposal
export interface IDashItemProposal {
  message: string;
  data: IDashItemProposalData[];
  meta: Meta;
}

export interface IDashItemProposalData {
  itemProposalId: string;
  itemId: string;
  item: Item;
  sender: Sender;
  recipient: Recipient;
  message: string;
  statusId: any;
  status: string;
  imageUrl: string;
  videoUrl: string;
  edited: boolean;
  hidden: boolean;
  dateModified: string;
  dateCreated: string;
}

export interface Item {
  id: string;
  title: string;
}

export interface Sender {
  userId: number;
  userName?: string;
  fullName?: string;
  organizationId: number;
  organizationName: string;
}

export interface Recipient {
  userId: number;
  userName?: string;
  fullName?: string;
  organizationId: number;
  organizationName: string;
}

export interface Meta {
  total: number;
}
