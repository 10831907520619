import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashitemsFormsModule } from '../../dashitems/dashitems-forms.module';
import { FundraiserDonationsFormsModule } from '../../fundraiser-donations/fundraiser-donations-forms.module';
import { WishlistFormsModule } from '../../wishlist/wishlist-forms.module';
import { CreateCauseCompComponent } from './create-cause-comp.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';



@NgModule({
  declarations: [CreateCauseCompComponent],
  imports: [
    IonicModule,
    RouterModule,
    CommonModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    FundraiserDonationsFormsModule,
    DashitemsFormsModule,
    WishlistFormsModule,
  ],
  exports: [CreateCauseCompComponent],
})
export class CreateCauseCompModule {}
