import { Component, Input, OnInit } from '@angular/core';
import { loadingRequest } from 'src/app/services/appconst';
import {
  DashitemServiceService,
  IDashItemData,
} from 'src/app/services/dashitem-service.service';
import { FundRaiserServiceService } from 'src/app/services/fund-raiser-service.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';

@Component({
  selector: 'app-edit-dash-item',
  templateUrl: './edit-dash-item.component.html',
  styleUrls: ['./edit-dash-item.component.scss'],
})
export class EditDashItemComponent implements OnInit {
  @Input() queryParams: any = {};

  dashItems: IDashItemData = null;
  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  loadingstate = loadingRequest.loading; // Set the Initial State to Loading

  constructor(
    public generalService: GeneralserviceService,
    public dashItemService: DashitemServiceService
  ) {}

  ngOnInit() {
    this.loadDashItems();
  }

  async loadDashItems() {
    this.loadingstate = loadingRequest.loading;

    await this.dashItemService.authservice.onLoad();

    await this.dashItemService
      .getDashItem(this.queryParams)
      .subscribe(
        (res) => {
          if (!res.meta.total) {
            this.loadingstate = loadingRequest.noData;
          } else if (res.meta.total) {
            this.loadingstate = loadingRequest.success;
            this.dashItems = res.data[0];
            // console.log(res.data[0]);
            this.loadingstate = loadingRequest.success;
          }
        },
        (err) => {
          this.loadingstate = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }

  refreshSecondaryImage($event) {
    // console.log($event);
    this.loadDashItems();
  }
}
