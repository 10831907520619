import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { loadingRequest } from 'src/app/services/appconst';
import {
  FundRaiserServiceService,
  IFundRaiserProjectData,
} from 'src/app/services/fund-raiser-service.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';

@Component({
  selector: 'app-edit-fundraiser-project',
  templateUrl: './edit-fundraiser-project.component.html',
  styleUrls: ['./edit-fundraiser-project.component.scss'],
})
export class EditFundraiserProjectComponent implements OnInit {
  @Input() queryParams: any = {};

  fundRaiserDetails: IFundRaiserProjectData = null;
  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  loadingstate = loadingRequest.loading; // Set the Initial State to Loading

  constructor(
    public generalService: GeneralserviceService,
    public fundRaiseService: FundRaiserServiceService
  ) {}

  ngOnInit() {
    this.loadFundRaiserDetails();
  }

  async loadFundRaiserDetails() {
    this.loadingstate = loadingRequest.loading;

    await this.fundRaiseService.authservice.onLoad();

    await this.fundRaiseService
      .getFundRaiser(this.queryParams)
      .subscribe(
        (res) => {
          if (!res.meta.total) {
            this.loadingstate = loadingRequest.noData;
          } else if (res.meta.total) {
            this.loadingstate = loadingRequest.success;
            this.fundRaiserDetails = res.data[0];

            // console.log(this.fundRaiserDetails);
          }
        },
        (err) => {
          this.loadingstate = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }
}
