import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthmodalserviceService } from '../../auth-comp/authmodal/service/authmodalservice.service';

@Component({
  selector: 'app-landingmenu',
  templateUrl: './landingmenu.component.html',
  styleUrls: ['./landingmenu.component.scss'],
})
export class LandingmenuComponent implements OnInit {
  @Input() fixedMenu = false;
  @Input() menuType: 'signup' | 'login' | 'main' = 'main';

  pathname = '';

  constructor(
    private router: Router,
    public authModalService: AuthmodalserviceService
  ) {
    router.events.subscribe((val) => {
      this.getpathname();
    });
  }

  ngOnInit() {}

  getpathname() {
    this.pathname = window.location.pathname;
  }
}
