import { Component, OnInit } from "@angular/core";
import { loadingRequest } from "src/app/services/appconst";
import {
  AuthApiserviceService,
  IVerificationForm,
  IVerificationFormBuilder,
  IVerificationMeta,
} from "src/app/services/authapiservice.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";

@Component({
  selector: "app-verification-card",
  templateUrl: "./verification-card.component.html",
  styleUrls: ["./verification-card.component.scss"],
})
export class VerificationCardComponent implements OnInit {
  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  loadingstate = loadingRequest.loading; // Set the Initial State to Loading
  verficationFormBuilderForm: IVerificationForm[];
  verficationFormBuilderMeta: IVerificationMeta;
  startproceessing = false;

  sampleRes: IVerificationForm[] = [
    {
      id: 1,
      title: "ID Card",
      fields: [
        {
          id: 2,
          title: "ID number",
          accept: "input",
          value: "",
          status: 0,
          message: "",
        },
        {
          id: 2,
          title: "ID File",
          accept: "file",
          value: null,
          status: 1,
          message: "",
        },
      ],
    },
    {
      id: 5,
      title: "Utility Bill",
      fields: [
        {
          id: 5,
          title: "Bill Type",
          accept: "select",
          value: "",
          status: 0,
          message: "",
          options: [
            {
              id: "4",
              title: "Electricity Bill",
            },
            {
              id: "6",
              title: "Some Bill",
            },
          ],
        },
        {
          id: 7,
          title: "Bill File",
          accept: "file",
          value: null,
          status: 1,
          message: "",
        },
      ],
    },
  ];

  constructor(
    public generalService: GeneralserviceService,
    public authService: AuthApiserviceService
  ) {}

  ngOnInit() {
    this.loadVerificationPage();
  }

  async loadVerificationPage() {
    this.loadingstate = loadingRequest.loading;

    await this.authService.onLoad();

    await this.authService
      .getVerificationOption({
        organizationId: this.authService.activeOrganisation.id,
        // typeId: 'individual',
      })
      .subscribe(
        (res: IVerificationFormBuilder) => {
          if (!res) {
            this.loadingstate = loadingRequest.noData;
          } else if (res) {
            console.log(res);
            this.verficationFormBuilderForm = res.data;
            this.verficationFormBuilderMeta = res.meta;
            this.loadingstate = loadingRequest.success;
            // console.log(res);
          }
        },
        (err) => {
          this.loadingstate = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }

  async requestVerification() {
    this.startproceessing = true;

    await this.authService.onLoad();

    await this.authService
      .getVerificationForm({
        organizationId: this.authService.activeOrganisation.id,
        // typeId: 'individual',
      })
      .subscribe(
        (res) => {
          this.generalService.presentToast(
            "Your Verification has being sent",
            "",
            2000,
            "toast-custom-class-success"
          );
          // console.log(res);
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || "Error Connecting to server",
            "",
            5000,
            "toast-custom-class-error"
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }
}
