import { Injectable } from "@angular/core";
import { CanLoad, Router } from "@angular/router";
import { Preferences } from "@capacitor/preferences";
import { Token_Key } from "../services/appconst";

@Injectable({
  providedIn: "root",
})
export class AuthguardGuard implements CanLoad {
  constructor(private router: Router) {}

  async canLoad(): Promise<boolean> {
    const tokenValue = await Preferences.get({ key: Token_Key });

    if (tokenValue.value?.length > 0) return true;
    else {
      this.router.navigateByUrl("/authpage", { replaceUrl: true });
      return false;
    }
  }
}
