import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';

@Component({
  selector: 'app-changepassword-card',
  templateUrl: './changepassword-card.component.html',
  styleUrls: ['./changepassword-card.component.scss'],
})
export class ChangepasswordCardComponent implements OnInit {
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  constructor(
    private fb: FormBuilder,
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {}

  ngOnInit() {
    this.myForm = this.fb.group({
      oldPassword: ['', [Validators.required, Validators.minLength(8)]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassWord: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  async proceed() {
    // Get Value from the form in form of an object
    let formValue: any = this.myForm.value;

    // Remove the Confirm Password Property is not necessary
    delete formValue.confirmPassWord;

    if (!this.myForm.valid) {
      this.generalService.presentToast('Form not valid, check your form input');
      return;
    }

    this.startproceessing = true;

    this.authservice
      .updatePassword(formValue)
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast('Your Password Has being Changed');
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || 'Unable to change Password'
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }
}
