import { Component, OnInit } from "@angular/core";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import { OverlayEventDetail } from "@ionic/core/components";

@Component({
  selector: "app-profile-top-card",
  templateUrl: "./profile-top-card.component.html",
  styleUrls: ["./profile-top-card.component.scss"],
})
export class ProfileTopCardComponent implements OnInit {
  isModalOpen: boolean = false;
  modalIndex = 0;
  modalTitle: string = "";

  constructor(public authservice: AuthApiserviceService) {
    this.refreshLocalUserDetails();
  }

  ngOnInit() {}

  async refreshLocalUserDetails() {
    await this.authservice.onLoad();
    // console.log(this.authservice.userDetails.jobTitle);
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    // console.log(ev.detail);
    this.isModalOpen = false;
  }

  toogleModal(isOpen: boolean, title?: string, index?: number) {
    this.isModalOpen = isOpen;
    this.modalTitle = title;
    this.modalIndex = index;
  }
}
