import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-confirm-email",
  templateUrl: "./confirm-email.component.html",
  styleUrls: ["./confirm-email.component.scss"],
})
export class ConfirmEmailComponent implements OnInit {
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;
  showResend = false;
  deviceSize;
  isMobileApp = false;

  @Output() loginStatusCode: EventEmitter<any> = new EventEmitter();
  @Output() reDirectionPage: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {
    this.logout();
    this.onInit();
  }

  async onInit() {
    this.deviceSize = await this.generalService.platformDimension();
    this.isMobileApp = environment.mobileApp;
    // console.log(this.deviceSize);
    this.sendOTPCode();
  }

  ionViewDidEnter() {
    this.startproceessing = false;
    this.logout();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      code: ["", [Validators.required, Validators.minLength(6)]],
    });
  }

  async logout() {}

  async sendOTPCode() {
    this.showResend = false;
    this.authservice.sendVerifyEmailToken().subscribe(
      (res) => {
        this.generalService.presentToast("An OTP has being sent to your email");
        this.showResend = true;
      },
      (err) => {
        this.generalService.presentToast(err.error?.message);
        this.showResend = true;
      }
    );
  }

  async proceed() {
    // Get Value from the form in form of an object
    const formValue: any = this.myForm.value;

    if (!this.myForm.valid) {
      this.generalService.presentToast("Form not valid, check your form input");
      return;
    }

    this.startproceessing = true;

    this.authservice
      .verifyEmail(formValue)
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast("Your Email had being verify");
          this.popStatusCode(res.status);
          this.navigate("dashboard");
        },
        (err) => {
          // console.log(err);
          this.popStatusCode(err.status);
          this.generalService.presentToast(err.error?.message);
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }

  navigate(pagename) {
    this.reDirectionPage.emit(pagename);
  }

  popStatusCode(index) {
    this.loginStatusCode.emit(index);
  }
}
