import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddFundraiserProjectComponent } from "./add-fundraiser-project/add-fundraiser-project.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { SwiperModule } from "swiper/angular";
import { AddFundraiserProjectStepFourComponent } from "./add-fundraiser-project/add-fundraiser-project-step-four/add-fundraiser-project-step-four.component";
import { AddFundraiserProjectStepOneComponent } from "./add-fundraiser-project/add-fundraiser-project-step-one/add-fundraiser-project-step-one.component";
import { AddFundraiserProjectStepThreeComponent } from "./add-fundraiser-project/add-fundraiser-project-step-three/add-fundraiser-project-step-three.component";
import { AddFundraiserProjectStepTwoComponent } from "./add-fundraiser-project/add-fundraiser-project-step-two/add-fundraiser-project-step-two.component";
import { EditFundraiserProjectFormComponent } from "./edit-fundraiser-project/edit-fundraiser-project-form/edit-fundraiser-project-form.component";
import { EditFundraiserProjectImageComponent } from "./edit-fundraiser-project/edit-fundraiser-project-image/edit-fundraiser-project-image.component";
import { EditFundraiserProjectComponent } from "./edit-fundraiser-project/edit-fundraiser-project.component";
import { SharedcomponentsModule } from "../sharedcomponents/sharedcomponents.module";
import { CustomCompModule } from "../custom-comp/custom-comp.module";
import { SafeSrcUrlModule } from "src/app/directives/safe-src-url.module";
import { EditorModule } from "@tinymce/tinymce-angular";
@NgModule({
  declarations: [
    AddFundraiserProjectComponent,
    AddFundraiserProjectStepOneComponent,
    AddFundraiserProjectStepTwoComponent,
    AddFundraiserProjectStepThreeComponent,
    AddFundraiserProjectStepFourComponent,
    EditFundraiserProjectComponent,
    EditFundraiserProjectFormComponent,
    EditFundraiserProjectImageComponent,
  ],
  imports: [
    IonicModule,
    RouterModule,
    CommonModule,
    SwiperModule,
    FormsModule,
    SharedcomponentsModule,
    ReactiveFormsModule,
    CustomCompModule,
    SafeSrcUrlModule,
    EditorModule,
  ],
  exports: [
    AddFundraiserProjectComponent,
    AddFundraiserProjectStepOneComponent,
    AddFundraiserProjectStepTwoComponent,
    AddFundraiserProjectStepThreeComponent,
    AddFundraiserProjectStepFourComponent,
    EditFundraiserProjectComponent,
  ],
})
export class FundraiserDonationsFormsModule {}
