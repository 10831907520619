import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { loadingRequest } from "src/app/services/appconst";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import {
  FundRaiserServiceService,
  IFundRaiserProjectData,
  IProjectCategoryData,
} from "src/app/services/fund-raiser-service.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-edit-fundraiser-project-form",
  templateUrl: "./edit-fundraiser-project-form.component.html",
  styleUrls: ["./edit-fundraiser-project-form.component.scss"],
})
export class EditFundraiserProjectFormComponent implements OnInit {
  @Input() fundRaiserDetails: IFundRaiserProjectData;

  editorConfig = {
    menubar: false, // Set this to false to remove the menu
    plugins: ["image"],
    toolbar:
      "formatselect | bold italic underline | alignleft aligncenter alignright alignjustify| undo redo  | image | ",
    images_upload_url:
      environment.apiBaseEndPoint + "/api/v1/misc/tiny-cloud/save-image.ashx",
    // Add other configurations as needed
  };

  categoryLoadingState = loadingRequest.idle;
  catogories: IProjectCategoryData[] = [];

  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  embedWarning = "";
  embedUrl = "";
  isYoutubeLinkValid = false;

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralserviceService,
    public authservice: AuthApiserviceService,
    public fundRaiseService: FundRaiserServiceService
  ) {
    this.loadCategory();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ID: [this.fundRaiserDetails.projectId, [Validators.required]],
      title: [this.fundRaiserDetails.title, [Validators.required]],
      category: [
        this.fundRaiserDetails.projectCategory.id,
        [Validators.required],
      ],
      projectcategoryid: [
        this.fundRaiserDetails.projectCategory.id,
        [Validators.required],
      ],
      amount: [this.fundRaiserDetails.amount, [Validators.required]],
      startDate: [
        this.fundRaiserDetails.startDate
          ? this.generalService.utcToHtmlDate(this.fundRaiserDetails.startDate)
          : "",
        [Validators.required],
      ],
      endDate: [
        this.fundRaiserDetails.endDate
          ? this.generalService.utcToHtmlDate(this.fundRaiserDetails.endDate)
          : "",
        [Validators.required],
      ],
      description: [this.fundRaiserDetails.description, [Validators.required]],
      videoUrl: [this.fundRaiserDetails.videoUrl],
      isPrivate: this.fundRaiserDetails.isPrivate,
    });
    this.checkYouTubeURLOnLoad();
    this.onVideoUrlChange();
  }

  async loadCategory() {
    this.categoryLoadingState = loadingRequest.loading;
    await this.fundRaiseService
      .getFundRaiserCategory({})
      .subscribe(
        (res) => {
          // console.log(res);
          this.catogories = res.data;
          this.categoryLoadingState = loadingRequest.success;
        },
        (err) => {
          this.categoryLoadingState = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }

  get videourl() {
    return this.myForm.get("videoUrl") as FormControl;
  }

  onVideoUrlChange() {
    this.videourl.valueChanges.subscribe((val) => {
      this.checkValidateYouTubeUrl(this.videourl.value);
    });
  }

  checkYouTubeURLOnLoad() {
    const isYoutubeLink = this.generalService.validateYouTubeUrl(
      this.fundRaiserDetails?.videoUrl || ""
    );
    this.embedUrl = isYoutubeLink.url;
    this.isYoutubeLinkValid = isYoutubeLink.state;
  }

  checkValidateYouTubeUrl(url: string) {
    this.embedWarning = "";
    const isYoutubeLink = this.generalService.validateYouTubeUrl(url);
    this.embedUrl = isYoutubeLink.url;
    this.isYoutubeLinkValid = isYoutubeLink.state;

    if (!isYoutubeLink.state) {
      this.embedWarning =
        "(Warning) Please the link you enter is not a valid youtube link";
    }
  }

  async proceed() {
    // If the Youtube is valid
    if (this.isYoutubeLinkValid) {
      this.myForm.patchValue({
        videoUrl: this.embedUrl,
      });
    } else {
      this.myForm.patchValue({
        videoUrl: "",
      });
    }
    this.myForm.get("videoUrl").updateValueAndValidity();
    // console.log(this.myForm.value);

    if (
      this.generalService.daysDifference(
        this.myForm.value?.startDate,
        this.myForm.value?.endDate
      ) < 0 ||
      this.generalService.daysDifference(
        this.myForm.value?.startDate,
        this.myForm.value?.endDate
      ) === NaN
    ) {
      this.generalService.presentToast(
        "First Date must be less than Second Date"
      );
      return;
    }

    if (
      this.generalService.daysDifference(null, this.myForm.value?.endDate) <
        0 ||
      this.generalService.daysDifference(null, this.myForm.value?.endDate) ===
        NaN
    ) {
      this.generalService.presentToast("End Date Must be A future Date");
      return;
    }

    this.startproceessing = true;

    this.fundRaiseService
      .updateFundRaiserProject(this.myForm.value)
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast(
            "Your FundRaiser Project has being Updated",
            "",
            2000,
            "toast-custom-class-success"
          );
          this.fundRaiseService.onReloadTriggered.next(true);
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || "Error Connecting to server",
            "",
            5000,
            "toast-custom-class-error"
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }
}
