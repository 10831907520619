/* eslint-disable max-len */
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { RouterModule } from "@angular/router";
import { ErrorCardComponent } from "./error-card/error-card.component";
import { LoadingCardComponent } from "./loading-card/loading-card.component";
import { NodataCardComponent } from "./nodata-card/nodata-card.component";
import { DashboardFundraisersStatiticsCardsComponent } from "./dashboard-fundraisers-statitics-cards/dashboard-fundraisers-statitics-cards.component";
import { DashboardTopDashitemsCardsComponent } from "./dashboard-top-dashitems-cards/dashboard-top-dashitems-cards.component";
import { DashboardTopFundraisersCardsComponent } from "./dashboard-top-fundraisers-cards/dashboard-top-fundraisers-cards.component";
import { DashboardTopProjectsCardsComponent } from "./dashboard-top-projects-cards/dashboard-top-projects-cards.component";
import { DashboardTopWishlistsCardsComponent } from "./dashboard-top-wishlists-cards/dashboard-top-wishlists-cards.component";
import { SwiperModule } from "swiper/angular";
import { DashboardTopProjectsCardsSlidesComponent } from "./dashboard-top-projects-cards/dashboard-top-projects-cards-slides/dashboard-top-projects-cards-slides.component";
import { NotificationsCardsComponent } from "./notifications-cards/notifications-cards.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PaginationComponent } from "./pagination/pagination.component";
import { DashboardHomeStatiticsComponent } from "./dashboard-home-statitics/dashboard-home-statitics.component";
import { FormSuccessAlertComponent } from "./form-success-alert/form-success-alert.component";

@NgModule({
  declarations: [
    ErrorCardComponent,
    LoadingCardComponent,
    NodataCardComponent,
    DashboardFundraisersStatiticsCardsComponent,
    DashboardTopDashitemsCardsComponent,
    DashboardTopFundraisersCardsComponent,
    DashboardTopProjectsCardsComponent,
    DashboardTopProjectsCardsSlidesComponent,
    DashboardTopWishlistsCardsComponent,
    NotificationsCardsComponent,
    PaginationComponent,
    DashboardHomeStatiticsComponent,
    FormSuccessAlertComponent,
  ],
  imports: [
    IonicModule,
    RouterModule,
    CommonModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ErrorCardComponent,
    LoadingCardComponent,
    NodataCardComponent,
    DashboardFundraisersStatiticsCardsComponent,
    DashboardTopDashitemsCardsComponent,
    DashboardTopFundraisersCardsComponent,
    DashboardTopProjectsCardsComponent,
    DashboardTopWishlistsCardsComponent,
    NotificationsCardsComponent,
    PaginationComponent,
    DashboardHomeStatiticsComponent,
    FormSuccessAlertComponent,
  ],
})
export class SharedcomponentsModule {}
