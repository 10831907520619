import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landingprefooter-one',
  templateUrl: './landingprefooter-one.component.html',
  styleUrls: ['./landingprefooter-one.component.scss'],
})
export class LandingprefooterOneComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
