import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { loadingRequest } from 'src/app/services/appconst';
import {
  AuthApiserviceService,
  IVerificationFormField,
  IVerificationFormFieldOption,
} from 'src/app/services/authapiservice.service';
import {
  IDashItemCategoryData,
  DashitemServiceService,
} from 'src/app/services/dashitem-service.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';

@Component({
  selector: 'app-verification-card-options-fields',
  templateUrl: './verification-card-options-fields.component.html',
  styleUrls: ['./verification-card-options-fields.component.scss'],
})
export class VerificationCardOptionsFieldsComponent implements OnInit {
  @Input() verificationFormField: IVerificationFormField;
  @Input() verificationFormOptionId: number;
  fieldName: string;

  myForm: FormGroup = new FormGroup({});

  options: IVerificationFormFieldOption[] = [];

  startproceessing = false;
  imagePreviewURL = 'assets/images/add-image.png';
  formData = new FormData();

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralserviceService,
    public dashItemService: DashitemServiceService,
    public authservice: AuthApiserviceService
  ) {}

  ngOnInit() {
    this.myForm = this.fb.group({
      // organizationId: [2, [Validators.required]],
      optionId: [this.verificationFormOptionId, [Validators.required]],
      fieldId: [this.verificationFormField.id, [Validators.required]],
      value: [this.verificationFormField.value, [Validators.required]],
    });
    this.fieldName = this.verificationFormField.title;
    this.options = this.verificationFormField?.options;
  }

  pickpicture() {
    document.getElementById('picFeatureImage').click();
  }

  // Image Preview
  showPreview($event) {
    const file = ($event.target as HTMLInputElement).files[0];
    this.myForm.patchValue({
      value: file,
    });
    this.myForm.get('value').updateValueAndValidity();
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreviewURL = reader.result as string;
      // console.log(this.addFundRaiserService.imagePreviewURL);
      // console.log(this.myForm.value);
    };
    reader.readAsDataURL(file);
  }

  async proceed() {
    // Get Value from the form in form of an object
    const formValue: any = this.myForm.value;
    // console.log(formValue);

    this.generalService.getAndAppendFormData(formValue, this.formData);

    // console.log(this.formData);

    if (!this.myForm.valid) {
      this.generalService.presentToast('Form not valid, check your form input');
      return;
    }

    this.startproceessing = true;

    this.authservice
      .updateVerificationForm(this.formData)
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast(
            'Data has being saved',
            '',
            2000,
            'toast-custom-class-success'
          );
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || 'Error Connecting to server',
            '',
            5000,
            'toast-custom-class-error'
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }
}
