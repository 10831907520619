/* eslint-disable max-len */
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChangepasswordCardComponent } from "./changepassword-card/changepassword-card.component";
import { EditprofileCardComponent } from "./editprofile-card/editprofile-card.component";
import { UpdatedpCardComponent } from "./updatedp-card/updatedp-card.component";
import { ViewprofileCardComponent } from "./viewprofile-card/viewprofile-card.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { RouterModule } from "@angular/router";
import { AddTeamMemberComponent } from "./add-team-member/add-team-member.component";
import { FundraiseDonatedListComponent } from "./fundraise-donated-list/fundraise-donated-list.component";
import { RecentactivitiesListComponent } from "./recentactivities-list/recentactivities-list.component";
import { TeamListsComponent } from "./team-lists/team-lists.component";
import { VerificationCardComponent } from "./verification-card/verification-card.component";
import { WishlistListActivitesComponent } from "./wishlist-list/wishlist-list.component";
import { EditTeamMemberComponent } from "./edit-team-member/edit-team-member.component";
import { SharedcomponentsModule } from "../sharedcomponents/sharedcomponents.module";
import { AddOrganisationComponent } from "./add-organisation/add-organisation.component";
import { OrganisationListComponent } from "./organisation-list/organisation-list.component";
import { EditOrganisationComponent } from "./edit-organisation/edit-organisation.component";
import { VerificationCardOptionsComponent } from "./verification-card/verification-card-options/verification-card-options.component";
import { VerificationCardOptionsFieldsComponent } from "./verification-card/verification-card-options/verification-card-options-fields/verification-card-options-fields.component";
import { ProfileDpAvatarComponent } from "./profile-dp-avatar/profile-dp-avatar.component";
import { ProfileTopCardComponent } from "./profile-top-card/profile-top-card.component";
import { CustomCompModule } from "../custom-comp/custom-comp.module";
import { ProfileContactSupportComponent } from "./profile-contact-support/profile-contact-support.component";
import { ProfileTopCardExploreComponent } from "./profile-top-card-explore/profile-top-card-explore.component";
import { ViewprofileCardExploreComponent } from "./viewprofile-card-explore/viewprofile-card-explore.component";

@NgModule({
  declarations: [
    ChangepasswordCardComponent,
    EditprofileCardComponent,
    UpdatedpCardComponent,
    ViewprofileCardComponent,
    AddTeamMemberComponent,
    AddOrganisationComponent,
    EditTeamMemberComponent,
    EditOrganisationComponent,
    FundraiseDonatedListComponent,
    RecentactivitiesListComponent,
    TeamListsComponent,
    OrganisationListComponent,
    VerificationCardComponent,
    VerificationCardOptionsComponent,
    VerificationCardOptionsFieldsComponent,
    WishlistListActivitesComponent,
    ProfileDpAvatarComponent,
    ProfileTopCardComponent,
    ProfileContactSupportComponent,
    ProfileTopCardExploreComponent,
    ViewprofileCardExploreComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    SharedcomponentsModule,
    CustomCompModule,
  ],
  exports: [
    ChangepasswordCardComponent,
    EditprofileCardComponent,
    EditTeamMemberComponent,
    EditOrganisationComponent,
    UpdatedpCardComponent,
    ViewprofileCardComponent,
    AddTeamMemberComponent,
    AddOrganisationComponent,
    FundraiseDonatedListComponent,
    RecentactivitiesListComponent,
    TeamListsComponent,
    OrganisationListComponent,
    VerificationCardComponent,
    VerificationCardOptionsComponent,
    VerificationCardOptionsFieldsComponent,
    WishlistListActivitesComponent,
    ProfileDpAvatarComponent,
    ProfileTopCardComponent,
    ProfileContactSupportComponent,
    ProfileTopCardExploreComponent,
    ViewprofileCardExploreComponent,
  ],
})
export class ProfilecomponentsModule {}
