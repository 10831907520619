/* eslint-disable @typescript-eslint/quotes */
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { loadingRequest } from "src/app/services/appconst";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import {
  DashitemServiceService,
  IDashItemCategoryData,
} from "src/app/services/dashitem-service.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-add-dash-item",
  templateUrl: "./add-dash-item.component.html",
  styleUrls: ["./add-dash-item.component.scss"],
})
export class AddDashItemComponent implements OnInit {
  categoryLoadingState = loadingRequest.idle;
  catogories: IDashItemCategoryData[] = [];

  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  imagePreviewURL = "assets/images/add-image.png";
  otherFeatureImage = ["assets/images/add-image.png"];
  formData = new FormData();
  maxFile = 10;
  showOtherPicture = false;
  formProcessSuccess = false;
  generatedUrl = "";

  editorConfig = {
    menubar: false, // Set this to false to remove the menu
    plugins: ["image"],
    toolbar:
      "formatselect | bold italic underline | alignleft aligncenter alignright alignjustify| undo redo  | image | ",
    images_upload_url:
      environment.apiBaseEndPoint + "/api/v1/misc/tiny-cloud/save-image.ashx",
    // Add other configurations as needed
  };

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralserviceService,
    public dashItemService: DashitemServiceService,
    public authservice: AuthApiserviceService
  ) {
    this.loadCategory();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      title: ["", [Validators.required]],
      description: ["", [Validators.required]],
      itemcategoryid: ["", [Validators.required]],
      photo: ["", [Validators.required]],
      isPrivate: false,
      noofitems: [1],
    });
  }

  pickpicture() {
    document.getElementById("picFeatureImage").click();
  }

  picOtherImage(index) {
    document.getElementById("picotherImagefromfile" + index).click();
  }

  removeOptionalImage(index) {
    if (index === this.otherFeatureImage.length - 1) {
      return;
    }

    if (this.formData.has("photo" + Number(index + 1))) {
      this.formData.delete("photo" + Number(index + 1));
    }
    this.otherFeatureImage.splice(index, 1);
  }

  async loadCategory() {
    this.categoryLoadingState = loadingRequest.loading;
    await this.dashItemService
      .getDashItemCategory({})
      .subscribe(
        (res) => {
          // console.log(res);
          this.catogories = res.data;
          this.categoryLoadingState = loadingRequest.success;
        },
        (err) => {
          this.categoryLoadingState = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }

  // Image Preview
  showPreview($event) {
    const file = ($event.target as HTMLInputElement).files[0];
    this.myForm.patchValue({
      photo: file,
    });
    this.myForm.get("photo").updateValueAndValidity();
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreviewURL = reader.result as string;
      // console.log(this.addFundRaiserService.imagePreviewURL);
    };
    reader.readAsDataURL(file);
    this.showOtherPicture = true;
  }

  showPreviewOthers($event, index) {
    const file = ($event.target as HTMLInputElement).files[0];
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.otherFeatureImage[index] = reader.result as string;

      if (this.otherFeatureImage.length - 1 === index && index < this.maxFile) {
        this.otherFeatureImage[index + 1] = "assets/images/add-image.png";
      }
    };
    reader.readAsDataURL(file);
    if (this.formData.has("photo" + Number(index + 1))) {
      this.formData.delete("photo" + Number(index + 1));
    }
    this.formData.append("photo" + Number(index + 1), file, file.name);
    // console.log(this.formData);
  }

  async proceed() {
    // Get Value from the form in form of an object
    const formValue: any = this.myForm.value;
    // console.log(formValue);

    this.generalService.getAndAppendFormData(formValue, this.formData);

    // console.log(this.formData);

    if (!this.myForm.valid) {
      this.generalService.presentToast("Form not valid, check your form input");
      return;
    }

    let totalEntry = 0;

    this.formData.forEach((x) => {
      totalEntry = Number(totalEntry) + 1;
      // console.log(x.toString());
    });

    this.formData.set("noofitems", String(Number(totalEntry) - 4));
    // console.log(String(Number(totalEntry) - 4));

    this.startproceessing = true;

    let successText =
      "Your Dash Item Has Being Added, It will take some hours before it will reflect.";

    if (!this.dashItemService.authservice.activeOrganisation?.verified) {
      successText =
        "Your Dash Item Has Being Added, It won't go public until you verify your Account. Go to your profile to verify your account";
    }

    this.dashItemService
      .addDashItem(this.formData)
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast(
            successText,
            "",
            2000,
            "toast-custom-class-success"
          );
          this.formProcessSuccess = true;
          this.generatedUrl = `${environment.appUrl}dashitem-details-site/${res?.data?.customUrl}/${res?.data.title}`;
          this.dashItemService.onReloadTriggered.next(true);
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || "Error Connecting to server",
            "",
            5000,
            "toast-custom-class-error"
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }
}
