/* eslint-disable max-len */
import { Injectable } from "@angular/core";
import { FundRaiserServiceService } from "src/app/services/fund-raiser-service.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AddFundraiserProjectService {
  stepLevel = 1;
  finalMessage = 0;
  messageColor = "green";
  startproceessing = false;
  formValue: IAddProjectFormValue = {};
  imagePreviewURL = "";
  generatedUrl = "";

  constructor(
    public generalService: GeneralserviceService,
    public fundRaiseService: FundRaiserServiceService
  ) {}

  async proceed() {
    // console.log(this.formValue);
    const formValuePost = this.generalService.getFormData(this.formValue);

    // console.log(formValuePost);
    this.startproceessing = true;

    let successText =
      "Your FundRaiser Donations Has Being Added, It will take some hours before it will reflect.";

    if (!this.fundRaiseService.authservice.activeOrganisation?.verified) {
      successText =
        "Your FundRaiser Donations Has Being Added, It won't go public until you verify your Account. Go to your profile to verify your account";
    }

    this.fundRaiseService
      .addFundRaiserProject(formValuePost)
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast(
            successText,
            "",
            2000,
            "toast-custom-class-success"
          );
          this.generatedUrl = `${environment.appUrl}fundraiserdonation-details-site/${res?.data?.customUrl}/${res?.data.title}`;
          this.stepLevel = 4;
          this.fundRaiseService.onReloadTriggered.next(true);
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || "Error Connecting to server",
            "",
            5000,
            "toast-custom-class-error"
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }
}

export interface IAddProjectFormValue {
  title?: string;
  projectcategoryid?: string;
  amount?: number;
  startDate?: string;
  endDate?: string;
  description?: string;
  photo?: string;
  p2penabled?: number;
  videourl?: string;
  parentid?: number;
}
