import { Component, OnInit } from "@angular/core";
import { loadingRequest } from "src/app/services/appconst";
import {
  DashitemServiceService,
  IDashItemData,
} from "src/app/services/dashitem-service.service";
import { FundRaiserServiceService } from "src/app/services/fund-raiser-service.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";

@Component({
  selector: "app-dashboard-top-dashitems-cards",
  templateUrl: "./dashboard-top-dashitems-cards.component.html",
  styleUrls: ["./dashboard-top-dashitems-cards.component.scss"],
})
export class DashboardTopDashitemsCardsComponent implements OnInit {
  topDashItems: IDashItemData[] = [];
  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  loadingstate = loadingRequest.loading; // Set the Initial State to Loading

  constructor(
    public generalService: GeneralserviceService,
    public dashItemService: DashitemServiceService
  ) {}

  ngOnInit() {
    this.loadDashItems();
  }

  async loadDashItems() {
    this.loadingstate = loadingRequest.loading;

    await this.dashItemService.authservice.onLoad();

    await this.dashItemService
      .getDashItem({ active: true, perpage: 8, isPrivate: false })
      .subscribe(
        (res) => {
          if (!res.meta.total) {
            this.loadingstate = loadingRequest.noData;
          } else if (res.meta.total) {
            this.loadingstate = loadingRequest.success;
            this.topDashItems = res.data;
            // console.log(res);
            this.loadingstate = loadingRequest.success;
          }
        },
        (err) => {
          this.loadingstate = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }
}
