import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nodata-card',
  templateUrl: './nodata-card.component.html',
  styleUrls: ['./nodata-card.component.scss'],
})
export class NodataCardComponent implements OnInit {
  @Input() title = '';
  @Input() errMsg = 'No Data was Returned';

  constructor() {}

  ngOnInit() {}
}
