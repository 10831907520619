import { Component, OnInit } from "@angular/core";
import { AddFundraiserProjectService } from "../services/add-fundraiser-project.service";

@Component({
  selector: "app-add-fundraiser-project-step-four",
  templateUrl: "./add-fundraiser-project-step-four.component.html",
  styleUrls: ["./add-fundraiser-project-step-four.component.scss"],
})
export class AddFundraiserProjectStepFourComponent implements OnInit {
  constructor(public addFundRaiserService: AddFundraiserProjectService) {}
  generatedUrl = "";

  ngOnInit() {
    this.generatedUrl = this.addFundRaiserService.generatedUrl;
  }

  async proceed() {
    this.addFundRaiserService.stepLevel = 1;
  }
}
