import { Component, Input, OnInit, ViewChild } from "@angular/core";
import Swiper, { SwiperOptions } from "swiper";
import { SwiperComponent } from "swiper/angular";

@Component({
  selector: "app-auth-display-slider",
  templateUrl: "./auth-display-slider.component.html",
  styleUrls: ["./auth-display-slider.component.scss"],
})
export class AuthDisplaySliderComponent implements OnInit {
  activeSlideIndex = 0;
  @ViewChild("swiperHomeSlide", { static: false }) swiper?: SwiperComponent;

  @Input() slides = [
    {
      image: "assets/images/authpic1.png",
      header: "Create and Manage <br /> Donations to your Fundraisers",
      body: "Your voice matters. Set up a cause, tell your <br />story and get help.",
    },
    {
      image: "assets/images/authpic2.png",
      header: "Give, Dash  and <br />Share  Love",
      body: "Send and receive funds/gifts from friends,<br /> family and supporters.",
    },
    {
      image: "assets/images/authpic3.png",
      header: "Create and Share <br /> Wishlists",
      body: "Share your Wishlists and get ready to tick off <br /> your boxes.",
    },
  ];

  config: SwiperOptions = {
    breakpoints: { 320: { slidesPerView: 1 }, 640: { slidesPerView: 1 } },
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: false,
    autoplay: {
      delay: 2900,
      disableOnInteraction: false,
    },
    pagination: false,
    effect: "fade",
    fadeEffect: { crossFade: true },
  };

  constructor() {}

  ngOnInit() {}

  onSwiper(swiper: Swiper) {
    setTimeout(() => {
      swiper.autoplay.start();
    }, 1000);
  }

  onSlideChange($event) {
    this.activeSlideIndex = this.swiper.swiperRef.activeIndex;
  }

  ngAfterViewInit(): void {
    // Start autoplay
    this.swiper.swiperRef.autoplay.start();
  }
}
