import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { CustomInputBoxComponent } from "./custom-input-box/custom-input-box.component";
import { CustomSelectBoxComponent } from "./custom-select-box/custom-select-box.component";

@NgModule({
  declarations: [CustomInputBoxComponent, CustomSelectBoxComponent],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [CustomInputBoxComponent, CustomSelectBoxComponent],
})
export class CustomCompModule {}
