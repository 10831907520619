import { Component, OnInit } from "@angular/core";
import { loadingRequest } from "src/app/services/appconst";
import { FundRaiserServiceService } from "src/app/services/fund-raiser-service.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import {
  IwishListData,
  WishlistServiceService,
} from "src/app/services/wishlist-service.service";

@Component({
  selector: "app-dashboard-top-wishlists-cards",
  templateUrl: "./dashboard-top-wishlists-cards.component.html",
  styleUrls: ["./dashboard-top-wishlists-cards.component.scss"],
})
export class DashboardTopWishlistsCardsComponent implements OnInit {
  topWishList: IwishListData[] = [];

  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  loadingstate = loadingRequest.loading; // Set the Initial State to Loading

  constructor(
    public generalService: GeneralserviceService,
    public wishListService: WishlistServiceService
  ) {}

  ngOnInit() {
    this.loadTopWishList();
  }

  async loadTopWishList() {
    this.loadingstate = loadingRequest.loading;

    await this.wishListService.authservice.onLoad();

    await this.wishListService
      .getWishList({ active: true, perpage: 6,isPrivate: false  })
      .subscribe(
        (res) => {
          if (!res.meta.total) {
            this.loadingstate = loadingRequest.noData;
          } else if (res.meta.total) {
            this.loadingstate = loadingRequest.success;
            this.topWishList = res.data;
            // console.log(res);
            this.loadingstate = loadingRequest.success;
          }
        },
        (err) => {
          this.loadingstate = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }
}
