import { Component, OnInit } from "@angular/core";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";

@Component({
  selector: "app-profile-top-card-explore",
  templateUrl: "./profile-top-card-explore.component.html",
  styleUrls: ["./profile-top-card-explore.component.scss"],
})
export class ProfileTopCardExploreComponent implements OnInit {
  constructor(public authservice: AuthApiserviceService) {
    this.refreshLocalUserDetails();
  }

  ngOnInit() {}

  async refreshLocalUserDetails() {
    await this.authservice.onLoad();
  }
}
