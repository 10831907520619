import { Component, OnInit, ViewChild } from '@angular/core';
import { loadingRequest } from 'src/app/services/appconst';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';
import { OrganisationsServiceService } from 'src/app/services/organisations-service.service';
import {
  IteamData,
  TeamServiceService,
} from 'src/app/services/team-service.service';
import { IonModal } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';
import { editOrganisationDataProps } from '../edit-organisation/edit-organisation.component';

@Component({
  selector: 'app-organisation-list',
  templateUrl: './organisation-list.component.html',
  styleUrls: ['./organisation-list.component.scss'],
})
export class OrganisationListComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;
  isModalOpen: boolean;
  modalTitle: string;
  editOrganisationData: editOrganisationDataProps;

  constructor(
    public generalService: GeneralserviceService,
    public organisationService: OrganisationsServiceService,
    public authservice: AuthApiserviceService
  ) {
    this.refreshOrganisation();
  }

  ngOnInit() {}

  async refreshOrganisation() {
    await this.authservice.onLoad();
    // console.log(this.authservice.userDetails.organizations);
  }

  deleteOrganisation(index, dontDelete: boolean = true) {
    if (dontDelete) {
      return;
    }

    const cssClass = 'toast-custom-class-success';

    this.generalService.presentBasicAlertDialog(
      '',
      'Delete',
      'Are you sure You want to Delete this Organisation',
      'Ok',
      'Cancel',
      () => {
        this.deleteConfirmed(
          this.authservice.userDetails.organizations[index].id
        );
      },
      () => {
        this.generalService.presentToast(
          'Operation Cancel',
          '',
          2000,
          'toast-custom-class-error'
        );
      }
    );
  }

  deleteConfirmed(id) {
    this.organisationService.deleteOrganisation({ id }).subscribe(
      (res) => {
        // console.log(res);
        this.generalService.presentToast(
          'Delete Successful',
          '',
          2000,
          'toast-custom-class-success'
        );

        this.refreshAuthUser();
        //this.loadTeams();
      },
      (err) => {
        // console.log(err);
        this.generalService.presentToast(
          err.error?.message || 'Error Connecting to server',
          '',
          5000,
          'toast-custom-class-error'
        );
      }
    );
  }

  selectOrganisation(index) {
    this.organisationService
      .updateActiveOrganization({
        organizationId: this.authservice.userDetails.organizations[index].id,
      })
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast(
            'Your Active Organisation Has being Updated',
            '',
            2000,
            'toast-custom-class-success'
          );
          this.refreshAuthUser();
          //this.loadTeams();
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || 'Error Connecting to server',
            '',
            5000,
            'toast-custom-class-error'
          );
        }
      );
  }

  async refreshAuthUser() {
    this.authservice.loginRefresh().subscribe(
      (res) => {
        // console.log(res);
        this.authservice.storeUserdetails(res.data.credentials);
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    // console.log(ev.detail);
    this.isModalOpen = false;
  }

  toogleModalEdit(
    isOpen: boolean,
    title?: string,
    index?: number,
    dontEdit: boolean = false
  ) {
    if (dontEdit) {
      return;
    }
    this.modalTitle = title;
    if (isOpen) {
      this.editOrganisationData = {
        id: this.authservice.userDetails.organizations[index].id,
        accessibility:
          this.authservice.userDetails.organizations[
            index
          ].type.toLocaleLowerCase() === 'public'
            ? 'true'
            : 'false',
        name: this.authservice.userDetails.organizations[index].name,
      };
      // console.log(this.editOrganisationData);
    }
    this.isModalOpen = isOpen;
  }
}
