import { Component, Input, OnInit } from '@angular/core';
import { IVerificationForm } from 'src/app/services/authapiservice.service';

@Component({
  selector: 'app-verification-card-options',
  templateUrl: './verification-card-options.component.html',
  styleUrls: ['./verification-card-options.component.scss'],
})
export class VerificationCardOptionsComponent implements OnInit {
  @Input() verificationForm: IVerificationForm;

  constructor() {
  }

  ngOnInit() {}
}
