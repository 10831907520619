/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() currentPage: number = 1;
  @Input() totalPages: number = 4;

  @Output() onPageChange: EventEmitter<any> = new EventEmitter();

  counter = Array;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {}

  checkCurrentPage(index) {
    return Number(this.currentPage) === Number(index + 1);
  }

  checkVisibility(index) {
    const curNumber = Number(index + 1);

    if (Number(this.currentPage) === Number(curNumber)) {
      return true;
    }
    if (Number(this.currentPage) === Number(curNumber + 1)) {
      return true;
    }
    if (Number(this.currentPage) === Number(curNumber + 2)) {
      return true;
    }
    if (Number(this.currentPage) === Number(curNumber - 1)) {
      return true;
    }
    if (Number(this.currentPage) === Number(curNumber - 2)) {
      return true;
    }

    if (Number(curNumber) === 1 || Number(curNumber) === this.totalPages) {
      return true;
    }
  }

  prevPage() {
    this.currentPage = this.currentPage - 1;
    if (this.currentPage < 1) {
      this.currentPage = 1;
    }
    this.onPageChange.emit(this.currentPage);
  }

  nextPage() {
    this.currentPage = Number(this.currentPage) + 1;
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }
    this.onPageChange.emit(this.currentPage);
  }

  gotoPage(page: number) {
    this.currentPage = page;
    this.onPageChange.emit(this.currentPage);
  }
}
