import { Component, Input, OnInit } from "@angular/core";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import { OverlayEventDetail } from "@ionic/core/components";
import { environment } from "src/environments/environment";
import { GeneralserviceService } from "src/app/services/generalservice.service";

@Component({
  selector: "app-viewprofile-card",
  templateUrl: "./viewprofile-card.component.html",
  styleUrls: ["./viewprofile-card.component.scss"],
})
export class ViewprofileCardComponent implements OnInit {
  @Input() selectCardIndex = 1;
  @Input() hideDetailsMobile = false;

  isModalOpen: boolean = false;
  modalIndex = 0;
  modalTitle: string = "";
  isMobileApp = false;

  constructor(
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {
    this.isMobileApp = environment.mobileApp;
    this.refreshLocalUserDetails();
  }

  ngOnInit() {}

  async refreshLocalUserDetails() {
    await this.authservice.onLoad();
    // console.log(this.authservice.activeOrganisation.name);
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    // console.log(ev.detail);
    this.isModalOpen = false;
  }

  toogleModal(isOpen: boolean, title?: string, index?: number) {
    this.isModalOpen = isOpen;
    this.modalTitle = title;
    this.modalIndex = index;
  }

  async deleteAccountModal() {
    this.generalService.presentBasicAlertDialog(
      "",
      "Delete",
      "Are you sure You want to Delete Your Account",
      "Ok",
      "Cancel",
      () => {
        this.generalService.presentToast(
          "An Email will be sent to you shortly for you to delete your account, If you do not get it in an hours time contact support"
        );
        // this.deleteAcccountNow();
      },
      () => {
        this.generalService.presentToast(
          "Operation Cancel",
          "",
          2000,
          "toast-custom-class-error"
        );
      }
    );
  }

  async deleteAcccountNow() {
    this.authservice.disabledAccount().subscribe(
      (res) => {
        this.generalService.presentToast(
          "An Email will be sent to you shortly for you to delete your account, If you do not get it in an hours time contact support"
        );
      },
      (err) => {
        this.generalService.presentToast("Unable to delete Account");
      }
    );
  }

  copyText() {
    // Get the text field
    const copyText: any = document.getElementById("linkText");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    window.navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    this.generalService.presentToast("Referral Code Copied");
  }
}
