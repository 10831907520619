import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";

@Component({
  selector: "app-editprofile-card",
  templateUrl: "./editprofile-card.component.html",
  styleUrls: ["./editprofile-card.component.scss"],
})
export class EditprofileCardComponent implements OnInit {
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;
  authData;

  constructor(
    private fb: FormBuilder,
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {}

  ngOnInit() {
    this.myForm = this.fb.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      otherNames: [""],
      phoneNumber: ["", [Validators.required, Validators.min(11)]],
      jobTitle: [""],
      bio: [""],
      username: [""],
    });

    this.getUpdateData();
  }

  async getUpdateData() {
    await this.authservice.onLoad();
    await this.myForm.setValue({
      ...this.myForm.value,
      firstName: this.authservice.userDetails.firstName || "",
      lastName: this.authservice.userDetails.lastName || "",
      otherNames: this.authservice.userDetails.otherNames || "",
      phoneNumber: this.authservice.userDetails.phoneNumber || "",
      jobTitle: this.authservice.userDetails.jobTitle || "",
      bio: this.authservice.userDetails.bio || "",
      username: this.authservice.userDetails.username || "",
    });
  }

  async proceed() {
    if (!this.myForm.valid) {
      this.generalService.presentToast("Form not valid, check your form input");
      return;
    }

    this.startproceessing = true;

    this.authservice
      .updateProfile(this.myForm.value)
      .subscribe(
        (res) => {
          // console.log(res);
          // this.authData = res;
          // this.authservice.storeLoginToken(this.authData.data?.token);
          // this.authservice.storeUserdetails(this.authData.data?.credentials);
          this.generalService.presentToast("Profile Updated");
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || "Error Updating Profile"
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }
}
