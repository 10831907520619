import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { loadingRequest } from "src/app/services/appconst";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import {
  IwishListData,
  WishlistServiceService,
} from "src/app/services/wishlist-service.service";

@Component({
  selector: "app-edit-wish-list",
  templateUrl: "./edit-wish-list.component.html",
  styleUrls: ["./edit-wish-list.component.scss"],
})
export class EditWishListComponent implements OnInit, OnDestroy {
  @Input() queryParams: any = {};
  wishList: IwishListData = null;

  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  loadingstate = loadingRequest.loading; // Set the Initial State to Loading
  private onEditDashItem$: Subscription;

  constructor(
    public generalService: GeneralserviceService,
    public wishListService: WishlistServiceService
  ) {}

  ngOnInit() {
    this.loadWishList();
    this.onEditDashItem$ = this.wishListService.onWishListItemsEdited.subscribe(
      (res) => {
        // console.log(res);
        if (res) {
          this.loadWishList();
        }
      }
    );
  }

  async loadWishList() {
    this.loadingstate = loadingRequest.loading;

    await this.wishListService.authservice.onLoad();

    await this.wishListService
      .getWishList(this.queryParams)
      .subscribe(
        (res) => {
          // console.log(this.queryParams);
          if (!res.meta.total) {
            this.loadingstate = loadingRequest.noData;
          } else if (res.meta.total) {
            this.loadingstate = loadingRequest.success;
            this.wishList = res.data[0];
            // console.log(res.data[0]);
            this.loadingstate = loadingRequest.success;
          }
        },
        (err) => {
          this.loadingstate = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }

  ngOnDestroy() {
    this.onEditDashItem$.unsubscribe();
  }
}
