import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthApiserviceService } from './authapiservice.service';

const apiBaseEndPoint = environment.apiBaseEndPoint;

// const STAGING = 'https://stagingapi.idashyou.ng/';
// const REMOTE_HOST = 'https://stagingapi.idashyou.ng/';

// if (environment.production) {
//   apiBaseEndPoint = REMOTE_HOST;
// }
const getNotification = `${apiBaseEndPoint}/api/v1/user/organization-activities/get-activities.ashx`;

@Injectable({
  providedIn: 'root',
})
export class NotificationServiceService {
  unReadNotification = 0;

  constructor(
    private httpClient: HttpClient,
    public authservice: AuthApiserviceService
  ) {
    this.getUnReadNotification();
  }

  getNotification(params: any): Observable<any> {
    return this.httpClient.get<any>(getNotification, {
      headers: this.authservice.header,
      params,
    });
  }

  // Get Unread Notification
  async getUnReadNotification() {
    await this.authservice.onLoad();
    await this.getNotification({
      organizationid: this.authservice.activeOrganisation.id,
      readstatus: false,
      perpage: 1,
    })
      .subscribe(
        (res) => {
          if (!res.meta.total) {
            this.unReadNotification = 0;
            return;
          }
          this.unReadNotification = res.meta.total;
        },
        (err) => {
          // console.log(err);
        }
      )
      .add(() => {});
  }
}

export interface INotification {
  message: string;
  data: INotificationData[];
  meta: Meta;
}

export interface INotificationData {
  organizationActivityId: string;
  title: string;
  message: string;
  template: string;
  type: string;
  readStatus: boolean;
  variables: Variable[];
  dateCreated: string;
}

export interface Variable {
  key: string;
  value?: string;
  type: number;
  placeholder: string;
}

export interface Meta {
  total: number;
}
