import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-fundraisers-statitics-cards',
  templateUrl: './dashboard-fundraisers-statitics-cards.component.html',
  styleUrls: ['./dashboard-fundraisers-statitics-cards.component.scss'],
})
export class DashboardFundraisersStatiticsCardsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
