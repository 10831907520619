import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthApiserviceService } from "./authapiservice.service";

const apiBaseEndPoint = environment.apiBaseEndPoint;

// const STAGING = 'https://stagingapi.idashyou.ng/';
// const REMOTE_HOST = 'https://stagingapi.idashyou.ng/';

// if (environment.production) {
//   apiBaseEndPoint = REMOTE_HOST;
// }

const addFundRaiserProject = `${apiBaseEndPoint}/api/v1/user/projects/add-project.ashx`;
const addFundRaiserProjectDonation = `${apiBaseEndPoint}/api/v1/user/project-donations/add-project-donation.ashx`;

const addFundRaiserProjectPledges = `${apiBaseEndPoint}/api/v1/user/project-pledges/add-project-pledge.ashx`;

const updateFundRaiserProject = `${apiBaseEndPoint}/api/v1/user/projects/update-project.ashx`;
const updateFundRaiserProjectImage = `${apiBaseEndPoint}/api/v1/user/projects/update-project-image.ashx`;
const updateFundRaiserProjectType = `${apiBaseEndPoint}/api/v1/user/projects/update-project-p2p.ashx`;

const withdrawProjectTemp = `${apiBaseEndPoint}/api/v1/user/projects/withdraw-temp-project.ashx`;
const withdrawProjectClose = `${apiBaseEndPoint}/api/v1/user/projects/withdraw-project.ashx`;
const deleteFundRaiserProject = `${apiBaseEndPoint}/api/v1/user/projects/delete-project.ashx`;
const promoteFundRaiserProjectApi = `${apiBaseEndPoint}/api/v1/user/project-promotions/add-project-promotion.ashx`;

const getFundRaiserProjectCateory = `${apiBaseEndPoint}/api/v1/user/project-categories/get-project-categories.ashx`;
const getFundRaiserProjectDonation = `${apiBaseEndPoint}/api/v1/user/project-donations/get-project-donations.ashx`;
const getFundRaiserProjectSummary = `${apiBaseEndPoint}/api/v1/user/projects/get-project-summary.ashx`;
const getFundRaiserApi = `${apiBaseEndPoint}/api/v1/user/projects/get-projects.ashx`;
const getExploreFundRaiserApi = `${apiBaseEndPoint}/api/v1/user/projects/explore-projects.ashx`;

@Injectable({
  providedIn: "root",
})
export class FundRaiserServiceService {
  selectedFundRaiser: IFundRaiserProjectData = null;
  onDonationSuccessful: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onReloadTriggered: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    public authservice: AuthApiserviceService
  ) {}

  //#region  Get Region

  getFundRaiser(params: any): Observable<IFundRaiserProject> {
    return this.httpClient.get<IFundRaiserProject>(getFundRaiserApi, {
      headers: this.authservice.header,
      params,
    });
  }

  getFundRaiserExplore(params: any): Observable<IFundRaiserProject> {
    return this.httpClient.get<IFundRaiserProject>(getExploreFundRaiserApi, {
      headers: this.authservice.header,
      params,
    });
  }

  getFundRaiserSummary(params: any): Observable<IProjectSummary> {
    return this.httpClient.get<IProjectSummary>(getFundRaiserProjectSummary, {
      headers: this.authservice.header,
      params,
    });
  }

  getFundRaiserCategory(params: any): Observable<IProjectCategory> {
    return this.httpClient.get<IProjectCategory>(getFundRaiserProjectCateory, {
      headers: this.authservice.header,
      params,
    });
  }

  getFundRaiserDonations(params: any): Observable<IFundRaiserProjectDonations> {
    return this.httpClient.get<IFundRaiserProjectDonations>(
      getFundRaiserProjectDonation,
      {
        headers: this.authservice.header,
        params,
      }
    );
  }

  //#region  Add Region

  addFundRaiserProject(body: any): Observable<any> {
    return this.httpClient.post<any>(addFundRaiserProject, body, {
      headers: this.authservice.header,
    });
  }

  addFundRaiserProjectDonation(body: any): Observable<any> {
    return this.httpClient.post<any>(addFundRaiserProjectDonation, body, {
      headers: this.authservice.header,
    });
  }

  addFundRaiserProjectPledges(body: any): Observable<any> {
    return this.httpClient.post<any>(addFundRaiserProjectPledges, body, {
      headers: this.authservice.header,
    });
  }

  //#region  Update Region

  updateFundRaiserProject(body: any): Observable<any> {
    return this.httpClient.post<any>(updateFundRaiserProject, body, {
      headers: this.authservice.header,
    });
  }

  updateFundRaiserProjectImage(body: any): Observable<any> {
    return this.httpClient.post<any>(updateFundRaiserProjectImage, body, {
      headers: this.authservice.header,
      responseType: "json",
      reportProgress: true, // this is used to show the progress of upload
      observe: "events", // this will help to print the refresh all observable including the progress
    });
  }

  updateFundRaiserProjectType(body: any): Observable<any> {
    return this.httpClient.post<any>(updateFundRaiserProjectType, body, {
      headers: this.authservice.header,
    });
  }

  promoteFundRaiserProject(body: any): Observable<any> {
    return this.httpClient.post<any>(promoteFundRaiserProjectApi, body, {
      headers: this.authservice.header,
    });
  }

  //#region Delete Region

  deleteFundRaiserProject(body: any): Observable<any> {
    return this.httpClient.post<any>(deleteFundRaiserProject, body, {
      headers: this.authservice.header,
    });
  }

  withDrawFundRaiserProject(body: any): Observable<any> {
    return this.httpClient.post<any>(withdrawProjectTemp, body, {
      headers: this.authservice.header,
    });
  }

  closeFundRaiserProject(body: any): Observable<any> {
    return this.httpClient.post<any>(withdrawProjectClose, body, {
      headers: this.authservice.header,
    });
  }
}

// Types for FundRaiser

// Type For FundRaiser Category

export interface IProjectCategory {
  message: string;
  data: IProjectCategoryData[];
  meta: IprojectMeta;
}

export interface IProjectCategoryData {
  projectCategoryId: string;
  title: string;
  imageUrl?: string;
  noOfActiveProjects?: number;
  dateModified: string;
  dateCreated: string;
}

interface IprojectMeta {
  total: number;
}

// Type for FundRiaser Project
export interface IFundRaiserProject {
  message: string;
  data: IFundRaiserProjectData[];
  meta: Meta;
}

export interface IFundRaiserProjectData {
  projectId: number;
  organization: Organization;
  title: string;
  description: string;
  amount: number;
  canWithdraw: boolean;
  canTempWithdraw: boolean;
  amountDonated?: number;
  amountWithdrawable?: number;
  amountWithdrawn?: any;
  percentageDonated?: number;
  p2pEnabled: boolean;
  imageUrl: string;
  parentId?: ParentId;
  parentProject?: any;
  projectCategory: ProjectCategory;
  startDate: string;
  endDate?: string;
  customUrl: string;
  goalReached: boolean;
  durationExpired: boolean;
  withdrawn: boolean;
  statusId: number;
  status: string;
  active: boolean;
  isPrivate: boolean;
  videoUrl: string;
  completed: boolean;
  dateModified: string;
  dateCreated: string;
}

export interface Organization {
  id: number;
  name: string;
  ownerId: number;
  verified: boolean;
  badges: Badge[];
  ownerDpurl?: string;
  owner?: string;
  user?: User;
}

export interface Badge {
  id: number;
  badgeId: number;
  title: string;
  imageUrl: any;
}

export interface User {
  name: string;
  id: number;
  fullName: string;
  username: string;
  email: string;
  dpUrl: string;
}

export interface ParentId {
  id: any;
  title: any;
}

export interface ProjectCategory {
  id: number;
  title: string;
}

export interface Meta {
  total: number;
  amountRaised?: number;
  totalAmountRaised?: number;
}

// Fund Raiser Doantion

export interface IFundRaiserProjectDonations {
  message: string;
  data: IFundRaiserProjectDonationsData[];
  meta: Meta;
}

export interface IFundRaiserProjectDonationsData {
  projectDonationId: number;
  projectId: number;
  project: Project;
  verified: boolean;
  amount: number;
  amountPaid: number;
  reference: string;
  message: string;
  anonymous: boolean;
  donor: Donor;
  recipient: Recipient;
  currency: string;
  paymentGateway: string;
  paymentReceivedAt: string;
  dateModified: string;
  dateCreated: string;
}

export interface Project {
  id: number;
  title: string;
}

export interface Donor {
  userId?: string;
  email: string;
  fullName: string;
  userName?: string;
  organizationId?: number;
  organizationName: string;
}

export interface Recipient {
  userId: string;
  email: string;
  fullName: string;
  userName?: string;
  organizationId: number;
  organizationName: string;
}

export interface Meta {
  total: number;
}

// Project Summary Stat
export interface IProjectSummary {
  message: string;
  data: IProjectSummaryData;
}

export interface IProjectSummaryData {
  noOfActiveProjects: number;
  amountRaised: number;
  amountDonated: number;
}
