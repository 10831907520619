import { Component, OnInit } from '@angular/core';
import { AuthmodalserviceService } from './service/authmodalservice.service';

@Component({
  selector: 'app-authmodal',
  templateUrl: './authmodal.component.html',
  styleUrls: ['./authmodal.component.scss'],
})
export class AuthmodalComponent implements OnInit {
  constructor(public authModalservice: AuthmodalserviceService) {}

  ngOnInit() {}
}
