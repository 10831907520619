import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthApiserviceService } from './authapiservice.service';

const apiBaseEndPoint = environment.apiBaseEndPoint;

// const STAGING = 'https://stagingapi.idashyou.ng/';
// const REMOTE_HOST = 'https://stagingapi.idashyou.ng/';

// if (environment.production) {
//   apiBaseEndPoint = REMOTE_HOST;
// }

const addOrganizationRoute = `${apiBaseEndPoint}/api/v1/user/organizations/add-organization.ashx`;
const getOrganizationRoute = `${apiBaseEndPoint}//api/v1/user/organizations/get-organizations.ashx`;
const deleteOrganizationRoute = `${apiBaseEndPoint}/api/v1/user/organizations/delete-organization.ashx`;
const updateOrganizationRoute = `${apiBaseEndPoint}//api/v1/user/organizations/update-organization.ashx`;
const updateActiveOrganizationRoute = `${apiBaseEndPoint}api/v1/user/auth/update-active-organization.ashx`;

@Injectable({
  providedIn: 'root',
})
export class OrganisationsServiceService {
  constructor(
    private httpClient: HttpClient,
    public authservice: AuthApiserviceService
  ) {}

  getOrganisation(params: any): Observable<any> {
    return this.httpClient.get<any>(getOrganizationRoute, {
      headers: this.authservice.header,
      params,
    });
  }

  addOrganisation(body: any): Observable<any> {
    return this.httpClient.post<any>(addOrganizationRoute, body, {
      headers: this.authservice.header,
    });
  }

  editOrganisation(body: any): Observable<any> {
    return this.httpClient.post<any>(updateOrganizationRoute, body, {
      headers: this.authservice.header,
    });
  }

  updateActiveOrganization(body: any): Observable<any> {
    return this.httpClient.post<any>(updateActiveOrganizationRoute, body, {
      headers: this.authservice.header,
    });
  }

  deleteOrganisation(body: any): Observable<any> {
    return this.httpClient.post<any>(deleteOrganizationRoute, body, {
      headers: this.authservice.header,
    });
  }
}

export type IOrganisations = {
  message: string;
  data: IOrganisationsData[];
  meta: {
    total: number;
  };
};

export type IOrganisationsData = {
  organizationMemberId: string | number;
  organizationId: string | number;
  user: {
    userId: string | number;
    name: string;
    email?: string;
    invitesStatus: string;
  };
  role: string;
  dateModified: Date;
  dateCreated: Date;
};
