import { Component, OnInit } from '@angular/core';
import { loadingRequest } from 'src/app/services/appconst';
import { FundRaiserServiceService } from 'src/app/services/fund-raiser-service.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';
import { NotificationServiceService } from 'src/app/services/notification-service.service';

@Component({
  selector: 'app-notifications-cards',
  templateUrl: './notifications-cards.component.html',
  styleUrls: ['./notifications-cards.component.scss'],
})
export class NotificationsCardsComponent implements OnInit {
  notifications = null;
  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  loadingstate = loadingRequest.loading; // Set the Initial State to Loading

  constructor(
    public generalService: GeneralserviceService,
    public notificationService: NotificationServiceService
  ) {}

  ngOnInit() {
    this.loadNotification();
  }

  async loadNotification() {
    this.loadingstate = loadingRequest.loading;

    await this.notificationService.authservice.onLoad();

    await this.notificationService
      .getNotification({
        organizationid:
          this.notificationService.authservice.activeOrganisation.id,
        perpage: 50,
        setasread: true, // -- Pending Backend Error is cleared
      })
      .subscribe(
        (res) => {
          if (!res.meta.total) {
            this.loadingstate = loadingRequest.noData;
          } else if (res.meta.total) {
            this.loadingstate = loadingRequest.success;
            this.notifications = res.data;
            // console.log(res.data);
            this.loadingstate = loadingRequest.success;
          }
        },
        (err) => {
          this.loadingstate = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {
        this.notificationService.getUnReadNotification();
      });
  }
}
