import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import { OrganisationsServiceService } from "src/app/services/organisations-service.service";

@Component({
  selector: "app-add-organisation",
  templateUrl: "./add-organisation.component.html",
  styleUrls: ["./add-organisation.component.scss"],
})
export class AddOrganisationComponent implements OnInit {
  accessibilityOptions = [false, true];
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralserviceService,
    public organisationService: OrganisationsServiceService,
    public authservice: AuthApiserviceService
  ) {}

  ngOnInit() {
    this.myForm = this.fb.group({
      name: ["", [Validators.required]],
      accessibility: ["", [Validators.required]],
    });
  }

  async proceed() {
    // Get Value from the form in form of an object
    const formValue: any = this.myForm.value;
    // console.log(formValue);

    if (!this.myForm.valid) {
      this.generalService.presentToast("Form not valid, check your form input");
      return;
    }

    this.startproceessing = true;

    this.organisationService
      .addOrganisation(formValue)
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast(
            "Organisation Has Being Created, You can close the Dialog",
            "",
            2000,
            "toast-custom-class-success"
          );
          this.refreshAuthUser();
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || "Error Connecting to server",
            "",
            5000,
            "toast-custom-class-error"
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }

  async refreshAuthUser() {
    this.authservice.loginRefresh().subscribe(
      (res) => {
        // console.log(res);
        this.authservice.storeUserdetails(res.data.credentials);
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  transformAccessibilityOptions(options) {
    return options ? "Public Services (Registered NGO)" : "Private Services (Personal Fundraisers)";
  }
}
