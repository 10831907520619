/* eslint-disable @angular-eslint/no-output-on-prefix */
import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';
import {
  DashitemServiceService,
  IDashItemData,
} from 'src/app/services/dashitem-service.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';

@Component({
  selector: 'app-edit-dash-item-secondary-images',
  templateUrl: './edit-dash-item-secondary-images.component.html',
  styleUrls: ['./edit-dash-item-secondary-images.component.scss'],
})
export class EditDashItemSecondaryImagesComponent implements OnInit {
  @Input() dashItems: IDashItemData;
  @Output() onSecondaryImageChange: EventEmitter<any> = new EventEmitter();

  img = 'assets/images/addimageicon.png';
  returnimagefile = '';
  hasimage = false;
  formData = null;
  uploadProgress = 0;
  isUploading = false;

  constructor(
    public authservice: AuthApiserviceService,
    public dashItemService: DashitemServiceService,
    public generalService: GeneralserviceService
  ) {}

  ngOnInit() {
    // console.log(this.dashItems.images);
  }

  removeOptionalImage(index) {
    const cssClass = 'toast-custom-class-success';

    this.generalService.presentBasicAlertDialog(
      '',
      'Delete',
      'Are you sure You want to Delete this Dash Item Image',
      'Ok',
      'Cancel',
      () => {
        this.deleteConfirmed(this.dashItems.images[index].id);
      },
      () => {
        this.generalService.presentToast(
          'Operation Cancel',
          '',
          2000,
          'toast-custom-class-error'
        );
      }
    );
  }

  deleteConfirmed(id) {
    this.dashItemService.deleteDashItemImage({ id }).subscribe(
      (res) => {
        // console.log(res);
        this.generalService.presentToast(
          'Delete Successful',
          '',
          2000,
          'toast-custom-class-success'
        );
        this.onSecondaryImageChange.emit(`Image with ID ${id} Removed`);
      },
      (err) => {
        // console.log(err);
        this.generalService.presentToast(
          err.error?.message || 'Error Connecting to server',
          '',
          5000,
          'toast-custom-class-error'
        );
      }
    );
  }

  pickpictureItemsSecondary() {
    document.getElementById('picfromfileItemsSecondary').click();
  }

  showpictureItemsSecondary(event) {
    const fileList = event.target.files;
    const myFile = fileList[0];

    const myFileName = myFile.name;
    const myFileSize = myFile.size;
    const myFileType = myFile.type;

    if (myFileSize > 1024 * 5000) {
      alert('Your Image exceed 5mb');
      return;
    }

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      // This help us to read the file in Base 64 Format for Preview
      reader.onload = (event: any) => {
        this.img = event.target.result;
        this.hasimage = true;
        this.returnimagefile = event.target.result;
        this.img = this.returnimagefile;
        this.uploadDpItemsSecondary();
      };
      reader.readAsDataURL(event.target.files[0]);
    }

    // Add the file to form data, which will be sent in the Upload Dp function
    this.formData = new FormData();
    Array.from(event.target.files).forEach((file: File) =>
      this.formData.append('photos', file, file.name)
    );
  }

  async clearFormData() {
    try {
      this.formData.delete('photos');
      this.formData.delete('id');
    } catch (err) {}
  }

  async uploadDpItemsSecondary() {
    this.formData.append('itemid', this.dashItems.itemId);
    this.isUploading = true;
    this.dashItemService
      .addDashItemImage(this.formData)
      .subscribe(
        (res) => {
          // console.log(res);
          if (res.type === HttpEventType.UploadProgress) {
            // Output the progress
            this.uploadProgress = Math.round((100 * res.loaded) / res.total);
          } else if (res.type === HttpEventType.Response) {
            // Once Upload is completed output 100%
            this.uploadProgress = 100;
            this.generalService.presentToast('Your Picture Has being updated');
            this.onSecondaryImageChange.emit(`Secondary Image have Added`);
          }
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || 'Unable to Load Profile'
          );
        }
      )
      .add(() => {
        this.isUploading = false;
        this.uploadProgress = 0;
        this.clearFormData();
      });
  }
}
