import { Component, OnInit } from "@angular/core";
import { AddFundraiserProjectService } from "../services/add-fundraiser-project.service";

@Component({
  selector: "app-add-fundraiser-project-step-three",
  templateUrl: "./add-fundraiser-project-step-three.component.html",
  styleUrls: ["./add-fundraiser-project-step-three.component.scss"],
})
export class AddFundraiserProjectStepThreeComponent implements OnInit {
  constructor(public addFundRaiserService: AddFundraiserProjectService) {}
  isValidVideoLink = false;

  ngOnInit() {
    this.addFundRaiserService.formValue.endDate;
    this.checkYouTubeURL();
    //console.log(this.addFundRaiserService.imagePreviewURL);
    // To do Saniter Image for preview
  }

  checkYouTubeURL() {
    this.isValidVideoLink =
      this.addFundRaiserService.generalService.validateYouTubeUrl(
        this.addFundRaiserService.formValue.videourl
      ).state;
  }

  async prevStep() {
    this.addFundRaiserService.stepLevel = 2;
  }

  async proceed() {
    this.addFundRaiserService.proceed();
    //this.addFundRaiserService.stepLevel = 4;
  }
}
