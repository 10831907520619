import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { loadingRequest } from "src/app/services/appconst";
import {
  FundRaiserServiceService,
  IFundRaiserProjectData,
} from "src/app/services/fund-raiser-service.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";

@Component({
  selector: "app-dashboard-top-projects-cards",
  templateUrl: "./dashboard-top-projects-cards.component.html",
  styleUrls: ["./dashboard-top-projects-cards.component.scss"],
})
export class DashboardTopProjectsCardsComponent implements OnInit, OnDestroy {
  @Input() slideType: "default" | "landing" = "default";
  topProjects: IFundRaiserProjectData[] = [];

  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  loadingstate = loadingRequest.loading; // Set the Initial State to Loading

  private onReload$: Subscription;

  constructor(
    public generalService: GeneralserviceService,
    public fundRaiseService: FundRaiserServiceService
  ) {}

  ngOnInit() {
    this.loadTopProjects();

    // Refresh the Page  when a changes is made in another component
    this.onReload$ = this.fundRaiseService.onReloadTriggered.subscribe(
      (res) => {
        // console.log(res);
        if (res) {
          this.loadTopProjects();
        }
      }
    );
  }

  async loadTopProjects() {
    this.loadingstate = loadingRequest.loading;

    await this.fundRaiseService.authservice.onLoad();

    await this.fundRaiseService
      .getFundRaiserExplore({ active: true, perpage: 4, isPrivate: false })
      .subscribe(
        (res) => {
          if (!res.meta.total) {
            this.loadingstate = loadingRequest.noData;
          } else if (res.meta.total) {
            this.loadingstate = loadingRequest.success;
            // console.log(res);
            this.topProjects = res.data;
            this.loadingstate = loadingRequest.success;
          }
        },
        (err) => {
          this.loadingstate = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }

  ngOnDestroy() {
    this.onReload$.unsubscribe();
  }
}
