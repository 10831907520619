/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatServiceService } from 'src/app/services/chat-service.service';
import { NotificationServiceService } from 'src/app/services/notification-service.service';

import { IonModal } from "@ionic/angular";
import { OverlayEventDetail } from "@ionic/core/components";


@Component({
  selector: "app-dashboard-menu-bar",
  templateUrl: "./dashboard-menu-bar.component.html",
  styleUrls: ["./dashboard-menu-bar.component.scss"],
})
export class DashboardMenuBarComponent implements OnInit {
  @Input() menuType: "navBar" | "dashBar" = "navBar";
  @Input() hideSearch: boolean = false;
  @Input() hidemenuBack: boolean = true;
  @Input() hidemenuHamburger: boolean = false;
  @Input() hideNotification: boolean = false;
  @Input() hideCreateCause: boolean = false;
  @Input() hideMessage: boolean = false;
  @Input() hideType: boolean = true;

  @Input() typesList: string[] = [];
  @Input() selectedType: any = "";

  @Input() searchText: any = "";
  @Input() placeholder: string = "Search";
  @Input() menuTitleMobile: string = "";

  @Output() onSearchTyping: EventEmitter<any> = new EventEmitter();
  @Output() onSearchChange: EventEmitter<any> = new EventEmitter();
  @Output() onTypeChange: EventEmitter<any> = new EventEmitter();

  isModalOpen: boolean = false;
  modalIndex = 0;
  modalTitle: string = "";

  constructor(
    public notificationService: NotificationServiceService,
    public chatMessageService: ChatServiceService
  ) {}

  ngOnInit() {}

  textChange() {
    this.onSearchTyping.emit(this.searchText);
  }

  textChangeOnce() {
    this.onSearchChange.emit(this.searchText);
  }

  typeChange() {
    this.onTypeChange.emit(this.selectedType);
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    // console.log(ev.detail);
    this.isModalOpen = false;
  }

  toogleModal(isOpen: boolean, title?: string, index?: number) {
    this.isModalOpen = isOpen;
    this.modalTitle = title;
    this.modalIndex = index;
  }
}
