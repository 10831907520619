import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';
import { AddWishListComponent } from './add-wish-list/add-wish-list.component';
import { EditWishListFormComponent } from './edit-wish-list/edit-wish-list-form/edit-wish-list-form.component';
import { EditWishListImageComponent } from './edit-wish-list/edit-wish-list-image/edit-wish-list-image.component';
import { EditWishListItemsComponent } from './edit-wish-list/edit-wish-list-items/edit-wish-list-items.component';
import { EditWishListComponent } from './edit-wish-list/edit-wish-list.component';
import { SharedcomponentsModule } from '../sharedcomponents/sharedcomponents.module';
import { WishlistIconsAddComponent } from './wishlist-icons-add/wishlist-icons-add.component';
import { WishlistIconsComponent } from './wishlist-icons/wishlist-icons.component';
import { CustomCompModule } from '../custom-comp/custom-comp.module';
import { SafeSrcUrlModule } from 'src/app/directives/safe-src-url.module';
import { EditorModule } from '@tinymce/tinymce-angular';



@NgModule({
  declarations: [
    AddWishListComponent,
    EditWishListComponent,
    EditWishListFormComponent,
    EditWishListImageComponent,
    EditWishListItemsComponent,
    WishlistIconsAddComponent,
    WishlistIconsComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    SharedcomponentsModule,
    SwiperModule,
    CustomCompModule,
    EditorModule,
    SafeSrcUrlModule,
  ],
  exports: [AddWishListComponent, EditWishListComponent],
})
export class WishlistFormsModule {}
