import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';

@Component({
  selector: 'app-updatedp-card',
  templateUrl: './updatedp-card.component.html',
  styleUrls: ['./updatedp-card.component.scss'],
})
export class UpdatedpCardComponent implements OnInit {
  imgDp: string = 'https://api.marvellousforex.com/files/dp/dp.png';
  returnimagefile = '';
  hasimage = false;
  formData = null;
  uploadProgress = 0;
  isUploading = false;

  constructor(
    public authservice: AuthApiserviceService,
    public generalService: GeneralserviceService
  ) {}

  ngOnInit() {
    this.loadDp();
  }

  async loadDp() {
    await this.authservice.onLoad();
    this.imgDp = await this.authservice.userDetails.dpUrl;
  }

  pickpicture() {
    document.getElementById('picfromfile').click();
  }

  showpicture(event) {
    var fileList = event.target.files;
    var myFile = fileList[0];

    const myFileName = myFile.name;
    const myFileSize = myFile.size;
    const myFileType = myFile.type;

    if (myFileSize > 1024 * 5000) {
      alert('Your Image exceed 5mb');
      return;
    }

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      // This help us to read the file in Base 64 Format for Preview
      reader.onload = (event: any) => {
        this.imgDp = event.target.result;
        this.hasimage = true;
        this.returnimagefile = event.target.result;
        this.imgDp = this.returnimagefile;
        this.uploadDp();
      };
      reader.readAsDataURL(event.target.files[0]);
    }

    // Add the file to form data, which will be sent in the Upload Dp function
    this.formData = new FormData();
    Array.from(event.target.files).forEach((file: File) =>
      this.formData.append('photos', file, file.name)
    );
  }

  async uploadDp() {
    this.isUploading = true;
    this.authservice
      .updateDp(this.formData)
      .subscribe(
        (res) => {
          // console.log(res);
          if (res.type === HttpEventType.UploadProgress) {
            // Output the progress
            this.uploadProgress = Math.round((100 * res.loaded) / res.total);
          } else if (res.type === HttpEventType.Response) {
            // Once Upload is completed output 100%
            this.uploadProgress = 100;
            this.generalService.presentToast(
              'Your Profile Picture Has being updated'
            );
            this.refreshUsersSession();
          }
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || 'Unable to Load Profile'
          );
        }
      )
      .add(() => {
        this.isUploading = false;
        this.uploadProgress = 0;
      });
  }

  async refreshUsersSession() {
    this.authservice.loginRefresh().subscribe(
      async (res) => {
        // console.log(res);
        this.authservice.storeUserdetails(res.data?.credentials);
        await this.authservice.onLoad();
      },
      (err) => {
        // console.log(err);
      }
    );
  }
}
