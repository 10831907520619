import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';
import { WishlistServiceService } from 'src/app/services/wishlist-service.service';

@Component({
  selector: 'app-wishlist-icons-add',
  templateUrl: './wishlist-icons-add.component.html',
  styleUrls: ['./wishlist-icons-add.component.scss'],
})
export class WishlistIconsAddComponent implements OnInit {
  formData = new FormData();
  startproceessing = false;
  iconPreviewURL = 'assets/images/add-image.png';

  myForm: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralserviceService,
    public wishListService: WishlistServiceService,
    public authservice: AuthApiserviceService
  ) {}

  ngOnInit() {
    this.myForm = this.fb.group({
      photo: ['', [Validators.required]],
    });
  }

  pickpicture() {
    document.getElementById('picIcon').click();
  }

  // Image Preview
  showPreview($event) {
    const file = ($event.target as HTMLInputElement).files[0];

    // Only use for varification the form data will send this
    this.myForm.patchValue({
      photo: file,
    });
    this.myForm.get('photo').updateValueAndValidity();

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.iconPreviewURL = reader.result as string;
      // console.log(this.addFundRaiserService.imagePreviewURL);
    };
    reader.readAsDataURL(file);

    if (this.formData.has('photo')) {
      this.formData.delete('photo');
    }
    this.formData.append('photo', file, file.name);
  }

  async uploadPicture() {
    this.startproceessing = true;

    this.wishListService
      .addWishListItemImage(this.formData)
      .subscribe(
        (res) => {
          this.generalService.presentToast(
            'Your Image has being added',
            '',
            2000,
            'toast-custom-class-success'
          );
          // console.log(res);
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || 'Error Connecting to server',
            '',
            5000,
            'toast-custom-class-error'
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }
}
