import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AuthApiserviceService } from "src/app/services/authapiservice.service";
import { GeneralserviceService } from "src/app/services/generalservice.service";
import { OrganisationsServiceService } from "src/app/services/organisations-service.service";
import { environment } from "src/environments/environment";
import { AddFundraiserProjectService } from "../services/add-fundraiser-project.service";

@Component({
  selector: "app-add-fundraiser-project-step-two",
  templateUrl: "./add-fundraiser-project-step-two.component.html",
  styleUrls: ["./add-fundraiser-project-step-two.component.scss"],
})
export class AddFundraiserProjectStepTwoComponent implements OnInit {
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;
  embedWarning = "";
  embedUrl = "";
  isYoutubeLinkValid = false;

  editorConfig = {
    menubar: false, // Set this to false to remove the menu
    plugins: ["image"],
    toolbar:
      "formatselect | bold italic underline | alignleft aligncenter alignright alignjustify| undo redo  | image | ",
    images_upload_url:
      environment.apiBaseEndPoint + "/api/v1/misc/tiny-cloud/save-image.ashx",
    // Add other configurations as needed
  };

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralserviceService,
    public organisationService: OrganisationsServiceService,
    public authservice: AuthApiserviceService,
    public addFundRaiserService: AddFundraiserProjectService
  ) {}

  ngOnInit() {
    this.myForm = this.fb.group({
      description: ["", [Validators.required]],
      photo: [null, [Validators.required]],
      isPrivate: false,
      videourl: ["", null],
    });
    this.onVideoUrlChange();
  }

  get videourl() {
    return this.myForm.get("videourl") as FormControl;
  }

  async prevStep() {
    this.addFundRaiserService.stepLevel = 1;
  }

  onVideoUrlChange() {
    this.videourl.valueChanges.subscribe((val) => {
      this.checkValidateYouTubeUrl(this.videourl.value);
    });
  }

  checkValidateYouTubeUrl(url: string) {
    this.embedWarning = "";
    const isYoutubeLink = this.generalService.validateYouTubeUrl(url);
    this.embedUrl = isYoutubeLink.url;
    this.isYoutubeLinkValid = isYoutubeLink.state;

    if (!isYoutubeLink.state) {
      this.embedWarning =
        "(Warning) Please the link you enter is not a valid youtube link";
    }
  }

  // Image Preview
  showPreview($event) {
    const file = ($event.target as HTMLInputElement).files[0];
    this.myForm.patchValue({
      photo: file,
    });
    this.myForm.get("photo").updateValueAndValidity();
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.addFundRaiserService.imagePreviewURL = reader.result as string;
      // console.log(this.addFundRaiserService.imagePreviewURL);
    };
    reader.readAsDataURL(file);
  }

  async proceed() {
    // If the Youtube is valid
    if (this.isYoutubeLinkValid) {
      this.myForm.patchValue({
        videourl: this.embedUrl,
      });
    } else {
      this.myForm.patchValue({
        videourl: "",
      });
    }
    this.myForm.get("videourl").updateValueAndValidity();

    // Get Value from the form in form of an object
    const formValue: any = this.myForm.value;
    // console.log(formValue);

    if (!this.myForm.valid) {
      this.generalService.presentToast("Form not valid, check your form input");
      return;
    }

    this.addFundRaiserService.formValue = {
      ...this.addFundRaiserService.formValue,
      ...formValue,
    };

    // console.log(JSON.stringify(this.addFundRaiserService.formValue));

    this.addFundRaiserService.stepLevel = 3;
  }
}
