import { Component, Input, OnInit } from '@angular/core';
import { loadingRequest } from 'src/app/services/appconst';
import {
  FundRaiserServiceService,
  IFundRaiserProjectDonationsData,
} from 'src/app/services/fund-raiser-service.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';

@Component({
  selector: 'app-fundraise-donated-list',
  templateUrl: './fundraise-donated-list.component.html',
  styleUrls: ['./fundraise-donated-list.component.scss'],
})
export class FundraiseDonatedListComponent implements OnInit {
  @Input() queryParams: any = {};

  donations: IFundRaiserProjectDonationsData[] = [];

  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  loadingstate = loadingRequest.loading; // Set the Initial State to Loading

  constructor(
    public generalService: GeneralserviceService,
    public fundRaiseService: FundRaiserServiceService
  ) {}

  ngOnInit() {
    this.loadDonations();
  }

  async loadDonations() {
    this.loadingstate = loadingRequest.loading;

    await this.fundRaiseService.authservice.onLoad();

    await this.fundRaiseService
      .getFundRaiserDonations({
        organizationid:
          this.fundRaiseService.authservice.activeOrganisation?.id,
        donororgid: this.fundRaiseService.authservice.activeOrganisation?.id,
        verified: 'true',
        perpage: 24,
        ...this.queryParams,
      })
      .subscribe(
        (res) => {
          if (!res.meta.total) {
            this.loadingstate = loadingRequest.noData;
          } else if (res.meta.total) {
            this.loadingstate = loadingRequest.success;
            this.donations = res.data;
            // console.log(res);
          }
        },
        (err) => {
          this.loadingstate = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }
}
