export const environment = {
  production: true,
  apiBaseEndPoint: "https://prodapi.idashyou.ng/",
  appUrl: "https://idashyou.ng/",
  paystackSecret: "pk_live_99bdb14983e7ec18910707d549a3d0b3d269eae6",
  paystackCurrency: "NGN",
  monnifyApiKey: "MK_TEST_FPF6523RFU",
  monnifyContractCode: "6285342640",
  monnifyCurrency: "NGN",
  siteAccessToken: "jdjdjdjncncjcjjcjcjcjc",
  mobileApp: false,
  ismobileWeb: false,
};
