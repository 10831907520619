import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-error-card',
  templateUrl: './error-card.component.html',
  styleUrls: ['./error-card.component.scss'],
})
export class ErrorCardComponent implements OnInit {
  @Input() title = '';
  @Input() errMsg = 'Unable to Load No Internet connection';
  @Input() icon = 'globe-outline';

  @Output() reLoad = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  reloadPage() {
    this.reLoad.emit(true);
  }
}
