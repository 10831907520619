import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeneralserviceService } from 'src/app/services/generalservice.service';
import { TeamServiceService } from 'src/app/services/team-service.service';

@Component({
  selector: 'app-edit-team-member',
  templateUrl: './edit-team-member.component.html',
  styleUrls: ['./edit-team-member.component.scss'],
})
export class EditTeamMemberComponent implements OnInit {
  @Input() editTeamData: editTeamDataProps;

  roles = ['admin', 'fundraiser', 'member'];

  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralserviceService,
    public teamService: TeamServiceService
  ) {}

  ngOnInit() {
    this.myForm = this.fb.group({
      Id: [this.editTeamData?.id],
      userEmail: [
        this.editTeamData?.email,
        [Validators.required, Validators.email],
      ],
      role: [this.editTeamData?.role, [Validators.required]],
    });
  }

  async proceed() {
    // Get Value from the form in form of an object
    let formValue: any = this.myForm.value;
    // console.log(formValue);

    if (!this.myForm.valid) {
      this.generalService.presentToast('Form not valid, check your form input');
      return;
    }

    this.startproceessing = true;

    this.teamService
      .editTeamRole(formValue)
      .subscribe(
        (res) => {
          // console.log(res);
          this.generalService.presentToast(
            'Role Updated',
            '',
            2000,
            'toast-custom-class-success'
          );
        },
        (err) => {
          // console.log(err);
          this.generalService.presentToast(
            err.error?.message || 'Error Connecting to server',
            '',
            5000,
            'toast-custom-class-error'
          );
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }
}

export type editTeamDataProps = {
  id: string | number;
  email: string;
  role: string;
};
