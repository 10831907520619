import { Directive, HostBinding, Input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Directive({
  selector: "[appSafeSrcUrl]",
})
export class SafeSrcUrlDirective {
  @Input() appSafeSrcUrl: string;

  constructor(private sanitizer: DomSanitizer) {}

  @HostBinding("src")
  get innerHtml(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.appSafeSrcUrl);
  }
}
