import { Component, OnInit } from '@angular/core';
import { loadingRequest } from 'src/app/services/appconst';
import { AuthApiserviceService, IRecentActivitesData } from 'src/app/services/authapiservice.service';
import { FundRaiserServiceService } from 'src/app/services/fund-raiser-service.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';

@Component({
  selector: 'app-recentactivities-list',
  templateUrl: './recentactivities-list.component.html',
  styleUrls: ['./recentactivities-list.component.scss'],
})
export class RecentactivitiesListComponent implements OnInit {
  recentActivites: IRecentActivitesData[];

  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  loadingstate = loadingRequest.loading; // Set the Initial State to Loading

  constructor(
    public generalService: GeneralserviceService,
    public authService: AuthApiserviceService
  ) {}

  ngOnInit() {
    this.loadRecentActivities();
  }

  async loadRecentActivities() {
    this.loadingstate = loadingRequest.loading;

    await this.authService.onLoad();

    await this.authService
      .getProfileActivites({ perpage : 20})
      .subscribe(
        (res) => {
          if (!res.meta.total) {
            this.loadingstate = loadingRequest.noData;
          } else if (res.meta.total) {
            this.loadingstate = loadingRequest.success;
            this.recentActivites = res.data;
            // console.log(res);
          }
        },
        (err) => {
          this.loadingstate = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }
}
